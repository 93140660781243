<template>
    <v-container class="mt-5 p-0 px-2">
        <v-card>
            <v-toolbar color="blue">
                <!-- MENU BUTTON & MENU -->
                <v-menu transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                        <v-app-bar-nav-icon v-bind="props" color="white"
                            v-tooltip:bottom="$t('journal.note-menu.title')">
                            <i class="fa-solid fa-person-dots-from-line"></i>
                        </v-app-bar-nav-icon>
                    </template>
                    <v-list>
                        <v-list-item @click="() => handleSaveDiagnosis(true)">
                            <template v-slot:prepend>
                                <v-icon color="primary" icon="mdi-folder-download-outline" class="mr-n4"></v-icon>
                            </template>
                            <v-list-item-title>{{ $t('journal.note-menu.save_as_draft') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title>{{ $t('journal.note-menu.set_default') }}</v-list-item-title>
                            <template v-slot:append>
                                <v-icon icon="mdi-menu-right" size="x-small"></v-icon>
                            </template>
                            <template v-slot:prepend>
                                <v-icon color="primary" icon="mdi-star-outline" class="mr-n4"></v-icon>
                            </template>
                            <v-menu :open-on-focus="false" activator="parent" open-on-hover submenu>
                                <v-list>
                                    <v-list-item v-for="(item, index) in diaLists" :key="item.id || index"
                                        :active="selectedDiaListId === item.id" :active-class="'v-list-item--active'"
                                        @click="updateSelectedDiaListName(item.name, item.id)">
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <!-- TITLE -->
                <v-app-bar-title>{{ $t('diagnose.diagnosis') }} </v-app-bar-title>
                <!-- MARKS AS DELETED - CLOSES FORM -->
                <v-btn icon v-tooltip:bottom="$t('journal.note-menu.delete')" @click="deleteDiagnosis()">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                <!-- SAVES AS NON DRAFT - CLOSES FORM -->
                <v-btn icon v-tooltip:bottom="$t('journal.note-menu.save')" @click="handleSaveDiagnosis(false)">
                    <v-icon>mdi-floppy</v-icon>
                </v-btn>
                <!-- CLOSE FORM -->
                <v-btn icon v-tooltip:bottom="$t('journal.note-menu.close')" @click="handleClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container>
                <v-row v-if="showSearch">
                    <v-col>
                        <v-autocomplete v-model="selectedItem" :label="$t('diagnose.search_diagnosis')"
                            density="compact" :placeholder="$t('diagnose.search_diagnosis')" :items="diaItems"
                            item-title="name" item-value="code" @update:search="onSearch" @update:focus="diaItems = []"
                            no-filter>
                            <template v-slot:item="{ item }">
                                <v-list-item @click="OnClickItems(item)">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-chip color="orange" density="compact" size="small">{{
                                                item.value }}</v-chip>
                                            {{ item.title.length > 71 ?
                                                item.title.slice(0, 71) + '...'
                                                : item.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:prepend-inner>
                                <v-menu>
                                    <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" variant="text" color="blue" append-icon="mdi-chevron-down"
                                            prepend-icon="mdi-shape-outline" size="small">
                                            {{ selectedDiaListName || $t('diagnose.select_dialist') }}
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in diaLists" :key="index" :value="index"
                                            @click="updateSelectedDiaListName(item.name, item.id)">
                                            {{ item.name }}
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="!showSearch">
                    <v-col>
                        <v-form>
                            <v-row>
                                <v-col cols="3">
                                    <v-text-field v-model="diaCode" :label="$t('diagnose.code')" />
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field v-model="diaName" :label="$t('diagnose.name')" />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="diaComment" rows="2" :label="$t('diagnose.comment')" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-select v-model="selectedType" :label="$t('diagnose.select_diagnose_type')"
                                        :items="[$t('diagnose.none'), $t('diagnose.chronic'), $t('diagnose.main'), $t('diagnose.secondary')]">
                                    </v-select>
                                </v-col>
                                <v-col cols="8" v-if="selectedType === 'Chronic'">
                                    <v-select v-model="selectedYear" :items="years" :label="$t('diagnose.since')" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import dialist from '@src/api/dialist'
import diaItem from '@src/api/diaItem'
import diagnose from '@src/api/diagnose';
import userSettings from '@src/api/userSettings';
const store = useStore();
import { useStore } from 'vuex';
import { useJournalStore } from '@src/stores/journalStore';
import { useSnackbarStore } from '@stores/snackbarStore';
import { useUserSettingStore } from '@src/stores/userSettingStore';
const journalStore = useJournalStore();
const snackbarStore = useSnackbarStore();
const userSettingStore = useUserSettingStore();
import { useI18n } from 'vue-i18n';

const props = defineProps({
    dataRowId: {
        type: Number,
        default: null
    },
    version: {
        type: Number,
        default: null
    }
});

const emit = defineEmits(['close']);

const isDraft = ref(true);
const showSearch = ref(true);
const diaLists = ref([]);
const selectedItem = ref('')
const selectedDiaListName = ref('');
const selectedDiaListId = ref('');
const diaItems = ref([]);
const diaName = ref('');
const diaCode = ref('');
const diaComment = ref('');
const selectedType = ref('None');
const selectedYear = ref('');
const dataRowID = ref(props.dataRowId);
const version = ref(props.version);
const DraftValue = ref('1')
const autoSaveValue = ref(0);
const snackbarTriggeredByButton = ref(false);
const tempSelectedDialist = ref('');
const patient_id = store.getters.currentPatientID;
const journal_type_id = store.getters.currentJournalTypeID;
const unit_id = store.getters.currentUnitID;
const userDataID = store.getters.currentUserDataID;
const { t } = useI18n();


let timer = null;

const startIdleTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(() => {
        autoSaveValue.value = 1;
        saveDiagnosis();
    }, 5000);
};

// click from buttons
const handleSaveDiagnosis = (draft) => {
    autoSaveValue.value = 1;
    snackbarTriggeredByButton.value = true;
    isDraft.value = draft;
    saveDiagnosis();
    handleClose();
};

const handleClose = () => {
    closeAttentionEditor();
    emit('close'); // emitting the data to the parent component
};

const closeAttentionEditor = () => {
    journalStore.resetEditor();
};

const fetchDataWithParams = async (api, params) => {
    return await api.index(params);
};

// For fetching the Dialist. 
const GetDiaListData = async () => {
    try {
        const [dialistData] = await Promise.all([
            fetchDataWithParams(dialist).catch(error => {
                console.error('Error fetching dialist data:', error);
                return [];
            }),
        ]);
        diaLists.value = dialistData.data.map(item => ({
            name: item.name,
            id: item.dia_list_id
        }));
        getDefaultDialistId();  // This function is for getting the default DiaListId to set and show the name in the drop down.
    } catch (error) {
        console.error('Error fetching data:', error);
        return { dialistData: [] };
    }
};

const updateSelectedDiaListName = async (name, dia_list_id) => {
    diaItems.value = [];
    selectedDiaListName.value = name;
    selectedDiaListId.value = dia_list_id;
    if (showSearch.value == true) {
        tempSelectedDialist.value = dia_list_id
    }
    // This is for updating the DiaListId when we select any other Dialist Item.
    const response = userSettingStore.setSettingBySlug('diagnosis_editor_default_dia_list_id', dia_list_id);
};

let searchTimeout = null;

const onSearch = (search) => {
    // clearTimeout(searchTimeout);
    // searchTimeout = setTimeout(() => {
    getDiaListItems(search);
    // }, 1000);
};

// For fetching the Dia items when we select a Dia-list
const getDiaListItems = async (search) => {
    if (search.length >= 1) {
        try {
            const response = await diaItem.index({ dia_list_id: selectedDiaListId.value, search: search });
            diaItems.value = response.data;
        } catch (error) {
            console.error('Failed to fetch items:', error);
        }
    } else {
        diaItems.value = [];
    }
};

const OnClickItems = (item) => {
    showSearch.value = !showSearch.value;
    diaName.value = item.raw.name;
    diaCode.value = item.raw.code;
    saveDiagnosis();
};

// For creating and editing diagnose.
const saveDiagnosis = async () => {
    const diagnoseData = {
        code: diaCode.value,
        name: diaName.value,
        chronic_dia: selectedType.value == "Chronic" ? '1' : '0',
        main_dia: selectedType.value == "Main" ? '1' : '0',
        sec_dia: selectedType.value == "Secondary" ? '1' : '0',
        comment: diaComment.value,
        chronic_year: selectedType.value == "Chronic" ? selectedYear.value : '',
        dia_list_id: tempSelectedDialist.value,
        patient_id: patient_id, // used from Vuex store
        journal_type_id: journal_type_id, // used from Vuex store
        unit_id: unit_id, // used from Vuex store
        deleted: 0,
        sign_wanted: 0,
        private: 0,
        data_row_origin_id: 1,
        created_by: userDataID, // used from Vuex store
        owned_by: userDataID, // used from Vuex store
        data_date: null,
        signed_by: null,
        signed_date: null,
        counter_signed_by: null,
        counter_signed_date: null,
        draft: isDraft.value,
        use_time: 0,
        autosave: autoSaveValue.value
    };

    try {
        if (!dataRowID.value) {
            const response = await diagnose.store(diagnoseData);
            if (response.Code) {
                dataRowID.value = response.DataRowID;
                // snackbarStore.activate('Diagnose created successfully !', 'success', 2000);
            }
        } else {
            await diagnose.update(dataRowID.value, diagnoseData);
            if (snackbarTriggeredByButton.value) {
                snackbarStore.activate('Diagnose edited successfully !', 'success', 2000);
                snackbarTriggeredByButton.value = false;
            }
        }
    } catch (error) {
        console.error('Failed to save or update diagnose:', error);
    }
};

const deleteDiagnosis = () => {
    // later
    // journalStore.setDeleteItem(dataRowId.value);
};

const ShowDiagnoseData = async () => {
    try {
        const response = await diagnose.show(journalStore.editor.dataRowId, {
            data_row_id: journalStore.editor.dataRowId,
            version: journalStore.editor.version
        });

        const data = response.data[0];
        diaCode.value = data.code
        diaName.value = data.name
        diaComment.value = data.comment
        selectedYear.value = data.chronic_year;
        selectedDiaListId.value = data.dia_list_id;
        tempSelectedDialist.value = data.dia_list_id
        dataRowID.value = data.data_row_id;

        if (data.chronic_dia === '1') {
            selectedType.value = 'Chronic';
        } else if (data.main_dia === '1') {
            selectedType.value = 'Main';
        } else if (data.sec_dia === '1') {
            selectedType.value = 'Secondary';
        }
    } catch (error) {
        console.error('Failed to fetch existing diagnose:', error);
    }
};

watch([diaName, diaCode, diaComment, selectedType, selectedYear], () => {
    startIdleTimer();
});

// This function is for getting the default DiaListID.
const getDefaultDialistId = async () => {
    const slugValue = userSettingStore.getSettingBysSlug('diagnosis_editor_default_dia_list_id');
    setDefaultDialistIdAndName(slugValue);
};

// This function is for setting the default DiaListID and Name in the select drop down.
const setDefaultDialistIdAndName = (slugValue) => {
    const defaultDialist = diaLists.value.find((diaList) => diaList.id == slugValue);
    selectedDiaListName.value = defaultDialist.name;
    selectedDiaListId.value = defaultDialist.id;
};

onMounted(() => {
    GetDiaListData();
    if (journalStore.editor.dataRowId != undefined) {
        showSearch.value = !showSearch.value;
        //For populating Diagnose Form while opening it from Journal View
        ShowDiagnoseData();
    }
});

const years = ref(
    Array.from({ length: new Date().getFullYear() - 1900 }, (_, i) => (new Date().getFullYear() - i).toString())
);
</script>
