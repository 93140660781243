<template>
  <!-- DEBUG -->
  <div v-if="1 < 0">
    <pre style="color:blue">{{ data }}</pre>
    <pre style="color:purple">{{ hasUnpaid }}</pre>
    <pre style="color:green">{{ hasPaid }}</pre>
  </div>

  <v-list-item v-if="status === 'DONE'" @click="agendaRowClicked()" :value="value" class="border-b-thin">
    <template v-slot:prepend>
      <v-chip
        prepend-icon="mdi-check"
        class="mr-5"
        size="large"
        color="success"
        variant="outlined"
      >{{ displayTime }}</v-chip>
    </template>
    <v-list-item-title>{{ data.PatientName }}</v-list-item-title>
    <v-list-item-subtitle>
      {{ $filters.codenumber(displayCodeNumber) }}
      <v-chip v-if="data.PatientAge" size="small">{{ data.PatientAge }}</v-chip>
    </v-list-item-subtitle>
    <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
  </v-list-item>

  <v-list-item v-if="status === 'UNPAID'" @click="agendaRowClicked()" :value="value" class="border-b-thin">
    <template v-slot:prepend>
      <v-chip
        prepend-icon="mdi-account-cash"
        class="mr-5"
        size="large"
        color="info"
      >{{ displayTime }}</v-chip>
    </template>
    <v-list-item-title>{{ data.PatientName }}</v-list-item-title>
    <v-list-item-subtitle>
      {{ $filters.codenumber(displayCodeNumber) }}
      <v-chip v-if="data.PatientAge" size="small">{{ data.PatientAge }}</v-chip>
    </v-list-item-subtitle>
    <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
  </v-list-item>

  <v-list-item v-if="status === 'LATE'" @click="agendaRowClicked()" :value="value" class="border-b-thin">
    <template v-slot:prepend>
      <v-chip
        prepend-icon="mdi-account-clock"
        class="mr-5"
        size="large"
        color="warning"
      >{{ displayTime }}</v-chip>
    </template>
    <v-list-item-title>{{ data.PatientName }}</v-list-item-title>
    <v-list-item-subtitle>
      {{ $filters.codenumber(displayCodeNumber) }}
      <v-chip v-if="data.PatientAge" size="small">{{ data.PatientAge }}</v-chip>
    </v-list-item-subtitle>
    <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
    <template v-slot:append>
      <v-chip v-if="lateMins > 0" color="warning" prepend-icon="mdi-timer-plus">{{ lateMins }}+</v-chip>
    </template>
  </v-list-item>

  <v-list-item v-if="status === 'ARRIVED'" @click="agendaRowClicked()" :value="value" class="border-b-thin">
    <template v-slot:prepend>
      <v-chip
        prepend-icon="mdi-account-check"
        class="mr-5"
        size="large"
        color="primary"
        variant="flat"
      >{{ displayTime }}</v-chip>
    </template>
    <v-list-item-title>{{ data.PatientName }}</v-list-item-title>
    <v-list-item-subtitle>
      {{ $filters.codenumber(displayCodeNumber) }}
      <v-chip v-if="data.PatientAge" size="small">{{ data.PatientAge }}</v-chip>
    </v-list-item-subtitle>
    <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
    <template v-slot:append>
      <v-chip v-if="lateMins > 0" color="primary" prepend-icon="mdi-timer-plus">{{ lateMins }}+</v-chip>
    </template>
  </v-list-item>

  <v-list-item v-if="status === 'ELSE'" @click="agendaRowClicked()" :value="value" class="border-b-thin">
    <template v-slot:prepend>
      <v-chip
        prepend-icon="mdi-account-clock"
        class="mr-5"
        size="large"
      >{{ displayTime }}</v-chip>
    </template>
    <v-list-item-title>{{ data.PatientName }}</v-list-item-title>
    <v-list-item-subtitle>
      {{ $filters.codenumber(displayCodeNumber) }}
      <v-chip v-if="data.PatientAge" size="small">{{ data.PatientAge }}</v-chip>
    </v-list-item-subtitle>
    <v-list-text class="text-caption">{{ data.Description }}</v-list-text>
  </v-list-item>

</template>
<script>

import { FIND_PATIENT_BY_CODENUMBER } from '@src/store/actionTypes';

export default {
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    hasUnpaid: {
      type: Array,
      required: false,
      default: () => []
    },
    hasPaid: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    displayTime() {
      return this.data.AppStart.split(' ')[1].slice(0, 5);
    },
    displayCodeNumber() {
      const codeNumber = this.data.PatientCodeNumber;
      if(codeNumber.indexOf('{') >= 0) {
        return 'Kennitala er ekki skráð';
      }
      return codeNumber;
    }
  },
  mounted() {
    const codeNumber = this.data.PatientCodeNumber;
    const arrived = this.data.Arrived;
    const hasInvoice = this.data.hasInvoice;
    const appStart = this.data.AppStart;
    const now = new Date(), appointment = new Date(appStart);
    const test = (now - appointment) / 60000;
    let late = false;

    if(test >= 1) {
      late = true;
      // console.log(codeNumber, 'IS LATE', parseInt(test), 'minutes')
      let lateMins = parseInt(test);
      // max shown is 60 min
      if(lateMins > 60) {
        lateMins = 60;
      } 
      else if(lateMins > 10) {
        lateMins -= lateMins % 10;
      }
      this.lateMins = lateMins;
    }

    const hasPaid = this.hasPaid.includes(codeNumber);
    const hasUnpaid = this.hasUnpaid.includes(codeNumber);

    // UNPAID
    if(hasUnpaid) {
      this.status = 'UNPAID';
      return;
    }
    // DONE
    if(arrived && hasPaid) {
      this.status = 'DONE';
      return;
    }
    // ARRIVED
    if(arrived && !hasInvoice) {
      this.status = 'ARRIVED';
      return;
    }
    // LATE
    if(!arrived && late) {
      this.status = 'LATE';
      return;
    }
    // ELSE
    this.status = 'ELSE';
  },
  data() {
    return {
      status: '',
      statusValues: ['UNPAID', 'DONE', 'LATE', 'ARRIVED', 'ELSE'],
      lateMins: 0
    }
  },
  methods: {
    agendaRowClicked() {
      const codenumber = this.data.PatientCodeNumber;
      this.$store.dispatch(FIND_PATIENT_BY_CODENUMBER, codenumber).then(res => {
        this.$router.push({ name: 'journal' });
      }).catch(err => {
          console.log(err)
      });
    }
  }
}
</script>

