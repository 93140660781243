<template>
    <div class="form-group">
        <div @click="onContainerClick" class="select-input-container">
            <div class="select-container">
                <div class="input-group">
                    <input 
                        v-model="input"
                        v-focus
                        @input="onInput"
                        @click="onClick"
                        @keyup.esc="onInputEsc"
                        @keyup.down.prevent="onDownKey"
                        @keyup.up="onInputUp"
                        @keydown.tab="onTabKey"
                        @keyup.delete="onInputDelete"
                        class="select-input form-control"
                        :class="{'is-invalid':error}"
                        type="text"
                        autocomplete="off"
                        :placeholder="$t('prescription.looking_for_prescription')"
                        ref="input"
                        :tabindex="String(tabindex)"
                        style="min-height: 2.5rem;"
                        >
                    <div class="input-group-append">
                        <button @click="onCloseClick" type="button" class="btn btn-outline-danger">&times;</button>
                    </div>
                </div>
            </div>
            <scroll-container 
                class="input-container" 
                v-show="expanded" 
                :scrolling="next !== undefined && next !== null"
                @enterViewport="getNextMedicines"
            >
                <navigatable 
                    :max="medicines.length" 
                    @change="onChange" 
                    @enter="onEnter"
                    @tab="onTabTable"
                    @escape="onEsc"
                    @start="onBeginReached"
                    @end="onEndReached"
                    ref="navi"
                >
                    <medicine-list 
                        :medicines="medicines"
                        :highlight="highlight"
                        :clickable="true"
                        :query="input"
                        @clicked="onTableClicked"
                    ></medicine-list>
                </navigatable>
            </scroll-container>
        </div>
    </div>
</template>
<script>
    import MedicineList from './medicineList';
    import localService from '../../../../../api/local';
    import ScrollContainer from '../../../../scrollContainer';
    import Navigatable from '../../../../navigatable';

    export default {
        components: {
            MedicineList,
            ScrollContainer,
            Navigatable
        },
        props: {
            error: {
                type: Boolean,
                required: false,
                default: false
            },
            tabindex: {
                required: false,
                default: ''
            },
        },
        data() {
            return {
                input: '',
                medicines: [],
                expanded: false,
                querying: false,
                next: undefined,
                selected: undefined,
                highlight: undefined,
                queryPromise: undefined
            }  
        },
        mounted() {
            document.addEventListener('click', this.onDocumentClick);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.onDocumentClick);
        },
        methods: {
            onClick() {
                if (this.medicines.length === 0) {
                    this.getMedicines();
                }
                this.startBrowsing();
            },
            onInput() {
                if (this.input !== '') {
                    this.startBrowsing();
                    this.getMedicines();
                } else {
                    this.getMedicines();
                }
            },
            onCloseClick() {
                this.clearItem();
                this.focusInput();
                this.getMedicines();
            },
            onDownKey() {
                if (!this.expanded) {
                    this.startBrowsing();
                    if (this.medicines.length === 0) {
                        this.getMedicines();
                    }
                }
                this.focusTable();
            },
            onTabKey() {
                this.stopBrowsing();
            },
            onEnter(selected) {
                this.selectItem(this.medicines[selected]);
                this.stopBrowsing();
            },
            onChange(selected) {
                this.highlight = selected;
            },
            onBeginReached() {
                this.focusInput();
                this.stopBrowsing();
            },
            onEsc() {
                this.focusInput();
                this.stopBrowsing();
            },
            onInputUp() {
                this.stopBrowsing();
            },
            onInputEsc() {
                if (this.input === '') {
                    this.stopBrowsing();
                } else {
                    this.input = '';
                    this.getMedicines();
                    this.startBrowsing();
                }
            },
            onEndReached() {
                this.getNextMedicines();
            },
            startBrowsing() {
                this.expanded = true;
            },
            stopBrowsing() {
                this.expanded = false;
                this.highlight = undefined;
            },
            onDocumentClick() {
                this.stopBrowsing();
            },
            onContainerClick(e) {
                e.stopPropagation();
            },
            onTableClicked(index) {
                this.selectItem(this.medicines[index]);
                this.stopBrowsing();
            },
            onTabTable() {
                this.stopBrowsing();
                this.focusInput();
            },
            onInputDelete() {
                if (this.input === '') {
                    this.getMedicines();
                }  
            },
            selectItem(item) {
                this.selected = item;
                this.input = item.name;
                this.$emit('selected', item);
                this.focusInput();
                this.stopBrowsing();
            },
            clearItem() {
                this.selected = undefined;
                this.input = '';
                this.$emit('deselected');
            },
            synonymSearch() {
                this.startBrowsing();
                this.focusInput();
                setTimeout(() => { // Override input after focus
                    this.input = this.selected === undefined ? '' : this.selected.atc;
                    this.getMedicines();
                }, 60);
            },
            // for use with modal (on autofill fail)
            atcSearch(atc_code) {
                this.startBrowsing();
                this.focusInput();
                setTimeout(() => {
                    this.input = atc_code;
                    this.getMedicines();
                }, 60);
            },
            async getMedicines() {
                if (this.queryPromise) {
                    this.queryPromise.then(() => {
                        this.queryPromise = undefined;
                        this.getMedicines();
                    });
                } else {
                    this.queryPromise = localService.searchMedicine(this.input).then(({ data, links }) => {
                        this.medicines = data;
                        this.next = links.next;
                    }).catch(() => {
                        this.next = undefined;
                    }).then(() => {
                        this.queryPromise = undefined;
                    })
                }
            },
            async getNextMedicines() {
                if (this.queryPromise) {
                    this.queryPromise.then(() => {
                        this.queryPromise = undefined;
                        this.getNextMedicines();
                    });
                } else {
                    if (this.next !== undefined && this.next !== null) {
                        this.queryPromise = localService.searchNextMedicine(this.next, this.input).then(({ data, links }) => {
                            this.medicines = this.medicines.concat(data);
                            this.next = links.next;
                        }).catch(() => {
                            this.next = undefined;
                        }).then(() => {
                            this.queryPromise = undefined;
                        })
                    }
                }
            },
            focusTable() {
                this.$refs.navi.focus();
            },
            focusInput() {
                this.$refs.input.focus();
                this.$refs.input.scrollIntoView(false);
            }
        },
    }
</script>
<style lang="scss" scoped>
    .select-input::-ms-clear {
        display: none;
    }
</style>
