<template>
    <v-card flat>
        <v-toolbar color="transparent">
            <v-avatar :color="hoverColor" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" density="compact">
                <v-icon v-if="isHovered" size="small" @click="editAttention(journalData)">mdi-pencil</v-icon>
                <template v-else>
                    <i v-if="journalData.type === '9'" :class="getCategoryIcon(journalData.data.category)"></i>
                    <i v-else class="fal fa-exclamation-triangle"></i>
                </template>
            </v-avatar>
            <v-toolbar-title>
                <strong> {{ journalData.type === '9' ? getTranslatedCategory(journalData.data.category) : $t('Warning') }} </strong>
				<v-chip v-if="isDraft()" prepend-icon="mdi-progress-alert" size="small" class="text-0 user-select-none ml-3" color="warning">
					{{ $t('journal.view.draft') }}
				</v-chip>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-chip prepend-icon="mdi-doctor" class="text-0 user-select-none" size="small">
                {{ journalData.user }}
            </v-chip>
        </v-toolbar>
        <div class="journal-indent">
            <v-card-text class="pt-0">
                <b>{{ journalData.data.text }}</b>
            </v-card-text>
            <!-- ATC CODE -->
            <div v-if="(journalData.data.atc_code && journalData.data.atc_code.trim() != '') || (journalData.data.atc_code_desc && journalData.data.atc_code_desc.trim() != '')">
                <p class="ml-4 mb-0">
                    <span class="pt-0 title">
                        {{ $t('journal.view.attention_atc_code') }}:
                    </span>
                    <span class="pt-0 ml-1">
                        {{ journalData.data.atc_code_desc }}
                        <v-chip class="ml-2 user-select-none" size="small">
                            {{ journalData.data.atc_code }}
                        </v-chip>
                    </span>
                </p>
            </div>
            <!-- COMMENT -->
            <div v-if="journalData.data.comment && journalData.data.comment.trim() != ''">
                <p class="ml-4 mb-0">
                    <span class="pt-0 title">
                        {{ $t('journal.view.comment') }}:
                    </span>
                    <span v-if="journalData.data.comment && journalData.data.comment.trim() != ''" class="pt-0 ml-1">
                        {{ journalData.data.comment }}
                    </span>
                </p>
            </div>
        </div>
    </v-card>
</template>

<script>
import AttentionEditor from '../../journalEditor/attentionEditor/attentionEditor.vue';

export default {
    components: {
        AttentionEditor
    },
    props: {
        journalData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            attentionItems: [],
            warningItems: [],
            hoverColor: 'blue',
            hoverColorwarning: 'red',
            isHovered: false,
            isAttentionEditorOpen: false,
            selectedDataRowId: null,
            selectedVersion: null,
            categoryIcons: {
                A: 'fal fa-person-dots-from-line',
                I: 'fal fa-virus',
                B: 'mdi mdi-water-alert',
                N: 'fal fa-exclamation-circle',
                P: 'fal fa-person-pregnant',
                L: 'fal fa-person-breastfeeding',
                M: 'mdi mdi-screw-round-top',
            },
        };
    },
    methods: {
        isDraft() {
            const draft = this.journalData['data']['metadata']['draft'];
            return draft === '1';
        },
        handleMouseEnter() {
            this.isHovered = true;
            this.hoverColor = 'grey';
            this.hoverColorwarning = 'grey';
        },
        handleMouseLeave() {
            this.isHovered = false;
            this.hoverColor = 'blue';
            this.hoverColorwarning = 'red';
        },
        getCategoryIcon(category) {
            return this.categoryIcons[category] || 'fal fa-exclamation-triangle';
        },
        editAttention(item) {
            const id = item.data.data_row_id;
            this.$emit('edit-item', { id: id, type: '9' });
        },
        getTranslatedCategory(category) {
            return this.$t(`attention.${category}`);
        },
    },
    mounted() {
        this.attentionItems = this.journalData;
        const type3Items = this.journalData;
        this.warningItems = type3Items;
    },
};
</script>
<style scoped>
.journal-indent {
    margin-left: 40px;
}
.title {
    font-size: 0.9rem;
    font-weight: bold;
}
</style>
