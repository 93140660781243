<template>
    <simple-modal v-if="active" :color="color" @close="$emit('close')">
        <template v-slot:header>
            <i class="fas fa-lock-alt fa-fw" /> {{ headerLabel }}
        </template>
        <slot>
            Þessi aðgerð er læst. Sláðu inn lykilorð til að halda áfram.
        </slot>
        <div v-if="errorMessage" class="alert alert-danger my-3">
            <h5><i class="fas fa-exclamation-triangle fa-fw" /> Auðkenning tókst ekki!</h5>
            <p>{{ errorMessage }}</p>
        </div>
        <div class="form-group mt-3">
            <label for="">Notandi</label>
            <input 
                v-model="currentUserUsername" 
                type="text" 
                class="form-control inactive" 
                disabled
            >
        </div>
        <div class="form-group">
            <label for="">Lykilorð</label>
            <div class="input-group">
                <input 
                    :id="'idus' + Math.floor(Math.random() * Date.now().toString())"
                    v-model="password" 
                    :type="passwordInputType" 
                    class="form-control"
                >
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary" @click="toggleShowPassword">
                        <i :class="{'fal':true, 'fa-eye':!showPassword, 'fa-eye-slash':showPassword}" />
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group pt-4">
            <label>Ert þú ekki <strong>{{ currentUserName }}</strong>? <a href="/logout"><i class="fal fa-sign-out" /> Útskrá</a></label>
        </div>
        <template v-slot:footer>
            <button class="btn btn-primary" @click="verifyPassword()">{{ continueButtonLabel }}</button>
            <button class="btn btn-outline-secondary" @click="$emit('close')">Hætta við</button>
        </template>
    </simple-modal>
</template>
<script>
import UserApi from '@src/api/userApi';
import SimpleModal from '@components/modalSimple';
import { mapGetters } from 'vuex';

export default {
    components: {
        SimpleModal
    },
    props: {
        active: {
            type: Boolean,
            required: true,
            default: true
        },
        color: {
            type: String,
            required: false,
            default: 'primary'
        },
        headerLabel: {
            type: String,
            required: false,
            default: 'Aðgerð læst!'
        },
        continueButtonLabel: {
            type: String,
            required: false,
            default: 'Áfram'
        },
    },
    data() {
        return {
            password: '',
            showPassword: false,
            errorMessage: undefined,
        };
    },
    computed: {
        ...mapGetters([
            'currentUserName',
            'currentUserUsername'
        ]),
        passwordInputType() {
            return (this.showPassword) ? 'text' : 'password';
        }
    },
    created() {},
    mounted() {
        this.reset();
    },
    methods: {
        toggleShowPassword() {
            this.showPassword = !this.showPassword; 
        },
        reset() {
            this.password = '';
            this.showPassword = false;
        },
        verifyPassword() {
            this.errorMessage = undefined;
            UserApi.verifyPassword({password: this.password}).then(response => {         
                this.$emit('authorize');
            }).catch(err => {
                console.log(err.response.data);
                this.errorMessage = err.response.data.message;                
            });
        }
    },
};
</script>
<style></style>