<template>
  <v-list-item>
    <v-list-subheader>
      <strong>{{ unitName }}</strong>
    </v-list-subheader>
    <journal-component :journalData="journalData" @edit-item="handleEditItem" />
  </v-list-item>
</template>
<script>
import JournalComponent from '@components/journal/journalview/journalComponent/journalComponent.vue';

export default {
  components: {
    JournalComponent
  },
  props: {
    journalData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ownerName() {
      return this.journalData.owner_name;
    },
    unitName() {
      return this.journalData.unit_name;
    }
  },
  methods: {
    handleEditItem(info) {
      this.$emit('edit-item', info);
    }
  }
};
</script>
