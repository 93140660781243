<template>
  <div class="tip-tap" @click="setFocus">
    <div v-if="editor">
      <!-- MARKS -->
      <div class="btn-group mr-2 mb-2">
        <!--Use type="button" to stop the page from reloading when clicking the button -->
        <button
          type ="button"  
          v-tooltip="'Highlight'" 
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <i class="fas fa-highlighter" />
        </button>
        <button 
          type ="button"
          v-tooltip="'Superscript'"
          :disabled="true"
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('superscript') }"
          @click="editor.chain().focus().toggleSuperscript().run()"
        >
          <i class="fas fa-superscript" />
        </button>
        <button 
          type ="button"
          v-tooltip="'Subscript'" :disabled="true" 
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('subscript') }"
          @click="editor.chain().focus().toggleSubscript().run()"
        >
          <i class="fas fa-subscript" />
        </button>
        <button 
          type ="button"
          v-tooltip="'Strikethrough'"
          :disabled="true" 
          :class="{ 'btn btn-outline-primary': true, 'active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <i class="fas fa-strikethrough" />
        </button>
      </div>
      <!-- HISTORY -->
      <div class="btn-group mr-2 mb-2">
        <button 
          type ="button"
          v-tooltip="'Undo'" 
          class="btn btn-outline-primary" @click="editor.chain().focus().undo().run()"
        >
          <i class="fal fa-undo" />
        </button>
        <button 
          type ="button"
          v-tooltip="'Redo'" 
          class="btn btn-outline-primary" 
          @click="editor.chain().focus().redo().run()"
        >
          <i class="fal fa-redo" />
        </button>
      </div>
    </div>
  <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit'; // imported starterkit
import Italic from '@tiptap/extension-italic'
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';

const CustomItalic = Italic.extend({
  addAttributes() {
    return {
      color: {
        default: null,
        renderHTML: () => {
          return {
            style: 'color: black; background-color: orange; font-style: normal;'
          }
        },
      },
    }
  }
});

export default {
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.initializeEditor();
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    setFocus() {
      this.editor.commands.focus();
    },
    initializeEditor() {
      try {
        this.editor = new Editor({
          content: this.modelValue,
          extensions: [
            CustomItalic,
            Superscript,
            Subscript,
            StarterKit
          ],
          onUpdate: ({ editor }) => {
            this.$emit('update:modelValue', editor.getHTML());
          },
        });
      } catch (error) {
        console.error('Editor initialization error:', error);
      }
    },
  },
  watch: {
    modelValue(newValue) {
      if (this.editor && this.editor.getHTML() !== newValue) {
        this.editor.commands.setContent(newValue, false);
      }
    },
  },
};
</script>

<style scoped>
.tip-tap {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  min-height: 200px;
}
.tip-tap:hover {
  border: 1px solid black;
  cursor: text;
}
</style>
