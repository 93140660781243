import ApiBase from './apibase';

export default new class SettingService extends ApiBase {
    /**
     * Gets all Settings
     * 
     * @returns {Promise}
     */
    getSettings() {
        return this.getData('setting');
    }

    /**
     * Get settings by category
     * 
     * @param {String} category
     * @returns {Promise} 
     */
    getSettingsByCategory(category) {
        return this.getData(`setting/${category}`);
    }

    /**
     * get settings value by settings ID
     */
    getSettingsValueBySlug(slug) {
        return this.getData(`setting/user/value/${slug}`);
    }

    /**
     *  toggle mute settings
     */
    toggleBoolSetting(slug, value) {
        return this.patch(`setting/user/toggle`, { slug, value})
    }

    getSystemSettings() {
        return this.getData('setting/system');
    }

    /**
     * Post a new Setting to DB.
     * 
     * @param {Object} params 
     * @returns {Promise}
     */
    postSetting(params) {
        return this.postData('setting', params);
    }

    /**
     * Post a new System Setting to DB.
     * 
     * @param {Object} params 
     * @returns {Promise}
     */
    postSystemSetting(params) {
        return this.postData('setting/system', params);
    }

    /**
     * Update specified setting in database.
     * 
     * @param {Number} id 
     * @param {Object} params 
     * @returns {Promise}
     */
    putSetting(id, params) {
        return this.putData(`setting/${id}`, params);
    }

    /**
     * Update specified setting in database.
     * 
     * @param {Number} id 
     * @param {Object} params 
     * @returns {Promise}
     */
    putSystemSetting(id, params) {
        return this.putData(`setting/system/${id}`, params);
    }

    getTerms() {
        return this.getData('setting/term');
    }

    getTermById(id, params) {
        return this.getData(`setting/term/${id}`, params);
    }

    getTermUsageById(id, params) {
        return this.getData(`setting/termusage/${id}`, params);
    }
}