<template>
  <v-container>
    <term-name v-model="termName"/>
    <v-date-input 
      density="compact" 
      variant="outlined"
      hide-details
      v-model="selectedDate"
      @input="updateDate"
      :value="formattedDate"
    />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import { defineComponent, ref, watch, computed } from 'vue';

export default defineComponent({
  name: 'DateValue',
  components: {
    TermName
  },
  props: {
    keywordData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const selectedDate = ref(null);
    const formattedDate = computed(() => {
      return selectedDate.value ? selectedDate.value.toISOString().split('T')[0] : '';
    });
    const minDate = '2016-06-15';
    const maxDate = '2030-03-20';
    const termName = ref('Date of Admission');

    const updateDate = (date) => {
      selectedDate.value = date;
      emit('update:keywordData', { ...props.keywordData, term_value: formattedDate.value });
    };

    watch(
      () => props.keywordData,
      (newVal) => {
        if (newVal) {
          termName.value = newVal.term_name || termName.value;
          const date = newVal.term_value ? new Date(newVal.term_value) : null;
          selectedDate.value = date;
        }
      },
      { immediate: true }
    );

    watch(termName, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_name: newVal });
    });

    watch(formattedDate, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_value: newVal });
    });

    return {
      selectedDate,
      formattedDate,
      termName,
      updateDate,
      minDate,
      maxDate
    };
  }
});
</script>
