// import show from '@src/components/cashRegister/show';
// import search from '@src/components/prescriptionCreate/prescriptionForm/medicine/search';
// import { type } from 'jquery';
import { defineStore } from 'pinia';

export const useJournalStore = defineStore('journal', {
  state: () => ({
    view: {},
    settings: {
      visible: false, // Show or hide the journal settings.
      sortOrder: 'desc',
      types: [2, 3, 4, 5, 7, 9, 13, 16, 18], // Journal types are the ModuelTypeIds in the DataRow table. Removing or Adding items in this array will filter the journalData by type on the Rest API side.
      externalServices: [{ name: 'Samtengt sjúkraskrá', active: false }],
    },
    editor: {
      dataRowId: undefined,
      type: undefined,
      templateId: undefined,
      version: undefined,
    },
    showEditor: false,
    beingEdited: {
      dataRowId: undefined,
      type: undefined
    },
    beingDeleted: undefined
  }),
  getters: {
    getEditor(state) {
      return state.editor;
    }
  },
  actions: {
    // for live update
    setDataRowInEditor(dataRowID, type) {
      this.beingEdited = { dataRowId: dataRowID, type: type };
    },
    unsetDataRowInEditor() {
      this.beingEdited = { dataRowId: undefined, type: undefined };
    },
    setDeleteItem(dataRowId) {
      this.beingDeleted = dataRowId; 
    },
    unsetDeleteItem() {
      this.beingDeleted = undefined; 
    },
    setEditor(editor) {
      // Spread existing state and incoming editor changes
      this.editor = { ...this.editor, ...editor };
    },
    setSettings(updatedTypes) {
      this.settings.types = updatedTypes;
      console.log(this.settings.types, 'Updated types in store');
    },
    resetEditor() {
      // Reset the editor back to its initial state
      this.editor = {
        dataRowId: undefined,
        type: undefined,
        templateId: undefined,
      };
    }
  }
});
