<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-md-offset-3 mt-4">
                <h1>{{ $t('login.logged_in') }}</h1>
                <hr>
                <user-details :user="$store.state.user"></user-details>
                <hr>
                <router-link class="btn btn-primary btn-block" :to="{name: 'user-edit'}">
                    {{ $t('invoice_create.edit') }}
                </router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-md-offset-3 mt-4">
                <h1> {{ $t('settings.user_settings') }}</h1>
                <hr>
                <user-settings></user-settings>
                <hr>
            </div>
        </div>
    </div>
</template>
<script>
    import UserDetails from '../userDetails';
    import UserSettings from '../userSettings';
    import SettingService from '@src/api/setting';

    export default {
        components: {
            UserDetails,
            UserSettings,
            
        },
        data() {
        return {
            include_o: 1,
            };
        },
        mounted() {
            this.getSetting();
        },
        methods: {
            t(key) {
            return this.$t(key);
        },
            toggleSound() {
                this.include_o = !this.include_o;
                SettingService.toggleBoolSetting('include_o', this.include_o);
            },
            getSetting() {
                SettingService.getSettingsValueBySlug('include_o').then(res => {
                    this.include_o = Boolean(res);
                });
            }
        }
    }
</script>
