<template>
  <v-container class="p-0">
    <term-name v-model="termName" />
    <tiptap-editor v-model="termValue" />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import TiptapEditor from '@src/components/tiptapEditor/tiptapEditor.vue';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'FormattedText',
  components: {
    TermName,
    TiptapEditor
  },
  props: {
    keywordData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const termName = ref('This is FormattedText Text');
    const termValue = ref('');

    watch(
      () => props.keywordData,
      (newVal) => {
        if (newVal) {
          termName.value = newVal.term_name || termName.value;
          termValue.value = newVal.term_value || termValue.value;
          let text = termValue.value;
          if(text === null || text.length === 0) {
            return;
          }
          if(text.indexOf('<p>') >= 0) {
              return;
          }
          if(text.indexOf('\n') > 0) {
              text = text.split('\n');
              for(let i=0; i < text.length; i++) {
                  text[i] = `<p>${text[i]}</p>`;
              }
              termValue.value = text.join('');
          }
        }
      },
      { immediate: true }
    );

    watch(termName, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_name: newVal });
    });

    watch(termValue, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_value: newVal });
    });

    return {
      termName,
      termValue
    };
  }
});
</script>

