<template>
  <v-app id="app">
    <v-toolbar color="transparent" class="pt-2">
      <v-app-bar-nav-icon class="ml-5" @click="toggleJournalSettingsSidebar"></v-app-bar-nav-icon>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="IsEditorCollapsed" icon @click="showEditor">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid class="scrollable-container px-2" @scroll="onScroll">
      <template v-for="date in groupedByDateKeys" :key="date">
        <journal-day 
          :date="date"
          :entries="groupedByDate[date]"
          :journalData="groupedByDate[date]"
          :compactDate="daysCompact"
          @edit-item="handleEditItem"
          @updateCollapse="updateCollapse"
        />
      </template>
      <div v-if="journalData.length === 0" class="no-more-data text-center">
        <h3 class="text-center">
          <v-icon color="grey" size="large">mdi mdi-file-alert</v-icon>
        </h3>
        <p class="text-grey text-center user-select-none">{{ $t('journal.view.no_data_found') }}</p>
        <p class="text-grey text-center user-select-none">
          <v-btn prepend-icon="mdi-plus" color="primary" @click="showEditor()">
            {{ $t('journal.view.add_new_entry') }}
          </v-btn>
        </p>
      </div>
      <!-- Loader and No More Data Message -->
      <div v-if="noDataLeft" class="no-more-data text-center">
        <v-row align-content="center" class="fill-height" justify="center">
          <v-col cols="6">
            <v-col class="text-subtitle-1 text-center" cols="12">
              <v-divider opacity="100%" color="primary"></v-divider>
              <p>{{ $t('journal.end_of_patient_history') }}</p>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="loading">
        <v-col cols="12" class="text-center my-0 py-0">
          <v-col class="text-subtitle-1 text-center user-select-none" cols="12">
            {{ $t('journal.loading_next_batch') }}
          </v-col>
          <v-progress-linear color="primary" height="6" indeterminate rounded
            style="width: 50%; margin: 0 auto;"></v-progress-linear>
          <v-col class="text-subtitle-1 text-center pb-0" cols="12">
            <v-icon>mdi-chevron-down</v-icon>
          </v-col>
        </v-col>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import JournalDay from './journalday/journalday.vue';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { getCurrentInstance } from 'vue';
import { watch } from 'vue';

import { useSidebarsStore } from '@stores/SidebarsStore';
import { useUserSettingStore } from '@stores/userSettingStore';

export default {
  data() {
    return {
      userStore: useUserSettingStore(),
      daysCompact: false
    }
  },
  props: {
    journalData: {
      type: Array,
      required: true,
    },
    IsEditorCollapsed: {
      type: Boolean,
      required: false,
      default: true,
    },
    onNoMoreData: {
      type: Boolean,
      required: false,
      // default: true,
    }
  },
  components: {
    JournalDay
  },
  created() {
    const setting = this.userStore.getJournalSettingsCompactSettings();
    this.daysCompact = setting.days;
    // listen to changes
    this.userStore.$subscribe((mutation) => {
      if (mutation.storeId === 'userSetting') {
        const updatedSettings = this.userStore.getJournalSettingsCompactSettings();
        this.daysCompact = updatedSettings.days;
      }
    });
  },
  setup(props) {
    const store = useStore();
    const patient_name = computed(() => store.getters.currentPatientName);
    const loading = ref(false);
    const noDataLeft = ref(false);

    const { proxy } = getCurrentInstance();
    const emit = proxy.$emit;

    const onScroll = (event) => {
      const target = event.target;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        loading.value = true;
        emit('fetch-more-data');
      }
    };
    const SideBarStore = useSidebarsStore();

    const toggleJournalSettingsSidebar = () => {
      const currentState = SideBarStore.getJournalSettingsSidebarState();
      SideBarStore.setShowJournalSettingsSidebar(!currentState);
    };

   

    // Watch for changes in journalData
    watch(
      () => props.journalData,
      (newData) => {
       
        if (props.onNoMoreData) {
          noDataLeft.value = true;
          loading.value = false;
        } else {
          noDataLeft.value = false;
        }
      },
      { deep: true }
    );
    return {
      patient_name,
      onScroll,
      loading,
      noDataLeft,
      toggleJournalSettingsSidebar
    };
  },
  computed: {
    sortedData() {
      let sortedData = this.journalData;
      return sortedData;
    },
    // Group the sorted data by date
    groupedByDate() {
      return this.journalData.reduce((grouped, item) => {
        const key = new Date(item.date).toISOString().split('T')[0];
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(item);
        return grouped;
      }, {});
    },
    groupedByDateKeys() {
      return Object.keys(this.groupedByDate);
    }
  },
  methods: {
    compactDate(date) {
      return this.collapsedDates.indexOf(date) >= 0;
    },
    handleEditItem(info) {
      this.$emit('edit-item', info);
    },
    showEditor() {
      this.$emit('show-editor');
    }
  }
};
</script>

<style scoped>
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollable-container {
  margin-bottom: 120px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
</style>
