<template>
    <div class="form-group">
      <label-basic v-if="label !== undefined" :required="required">
        {{ label }}
      </label-basic>
      <div class="input-group">
        <span class="input-group-prepend">
          <button @click="decValue" type="button" class="btn btn-outline-secondary">-</button>
        </span>
        <input 
          v-model="internalValue" 
          type="text" 
          class="form-control text-center"
          :class="{'is-invalid': error}"
          :tabindex="String(tabindex)"
          style="min-height: 2.5rem;"
        >
        <span class="input-group-append">
          <button @click="incValue" type="button" class="btn btn-outline-secondary">+</button>
        </span>
      </div>
    </div>
  </template>
  
  <script>
import { ref, watch, onMounted } from 'vue';
import LabelBasic from '../labelBasic';

export default {
  components: {
    LabelBasic
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: 0
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    tabindex: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const internalValue = ref(Number(props.value));

    watch(() => props.value, (newValue) => {
      internalValue.value = Number(newValue);
    });

    function incValue() {
      let newValue = Number(internalValue.value) + 1;
      if (props.max !== undefined && newValue > props.max) {
        newValue = props.max;
      }
      internalValue.value = newValue;
      emit('update:value', newValue);
    }

    function decValue() {
      let newValue = Number(internalValue.value) - 1;
      if (props.min !== undefined && newValue < props.min) {
        newValue = props.min;
      }
      internalValue.value = newValue;
      emit('update:value', newValue);
    }

    function resetValue() {
      internalValue.value = 0;
      emit('update:value', 0);
    }

    return { internalValue, incValue, decValue, resetValue };
  }
};
</script>
