<template>
	<v-card class="mx-0 px-0">
		<v-toolbar class="note-button note-line" color="transparent" @click="collapseNoteFunction">
			<v-avatar @mouseenter="hovered = true" @mouseleave="hovered = false" :color="getHoverColor()" density="compact">
				<v-icon @click.stop="editNote(journalData)" size="small">{{ getAvatarIcon() }}</v-icon>
			</v-avatar>
			<v-toolbar-title class="d-flex align-items-center">
				<strong class="user-select-none">{{ journalData.data.title }}</strong>
				<v-chip v-if="isDraft()" prepend-icon="mdi-progress-alert" size="small" class="text-0 user-select-none ml-3" color="warning">
					{{ $t('journal.view.draft') }}
				</v-chip>
			</v-toolbar-title>
			<v-chip prepend-icon="mdi-doctor" size="small" class="text-0 user-select-none">
				{{ journalData.data.metadata.owner_name }}
				<!-- {{ journalData.data.data_row_id }} -->
			</v-chip>
			<v-icon v-if="compactNote" class="mr-3">mdi-unfold-less-horizontal</v-icon>
			<v-icon v-else class="mr-3">mdi-unfold-more-horizontal</v-icon>
		</v-toolbar>
		<div class="mx-0 px-0 pt-0" v-if="!compactNote">
			<v-scroll-y-transition appear leave>
				<div>
					<div class="m-0" v-for="(keyword, index) in journalData.data.cbm_keyword" :key="index">
						<v-card-title class="my-0 py-0 title">{{ keyword.term_name }}</v-card-title>
						<v-card-text v-if="hasContent(keyword)" class="content pt-1 pb-0" >
							<readOnlyTipTap :content="getContent(keyword)" class="p-0 m-0 mb-n3"/>
						</v-card-text>
					</div>
				</div>
			</v-scroll-y-transition>
		</div>
	</v-card>
</template>

<script>

import { useUserSettingStore } from '@stores/userSettingStore';
import readOnlyTipTap from './noteReadOnlyTipTap.vue';

export default {
	components: { readOnlyTipTap },
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			hovered: false,
			userStore: useUserSettingStore(),
			compactNote: false
		};
	},
	computed: {
		isCollapsed() {
			return this.compactNote;
		}
	},
	mounted() {
		for(let item of this.journalData.data.cbm_keyword) {
			let text = item.text;
			if(text === null || text.length === 0) {
				continue;
			}
			text = item.text;
			if(text.indexOf('<p>') >= 0) {
				continue;
			}
			if(text.indexOf('\n') < 0) {
				item.text = '<p>'+text+'</p>';
			} else {
				text = text.split('\n');
				for(let i=0; i < text.length; i++) {
					text[i] = '<p>'+text[i]+'</p>';
				}
				item.text = text.join('');
			}
		}

		// detect collapse changes in journal setting
		this.userStore.$subscribe((mutation) => {
			if(mutation.storeId === 'userSetting') {
				const updatedSettings = this.userStore.getJournalSettingsCompactSettings();
				this.compactNote = updatedSettings.notes;
			}
		});

		const setting = this.userStore.getJournalSettingsCompactSettings();
		if(setting.notes) {
			this.compactNote = true;
		}
	},
	methods: {
		isDraft() {
			const draft = this.journalData['data']['metadata']['draft'];
			return draft === '1';
		},
		getContent(keyword) {
			return keyword.term_value && keyword.term_value != '' ? keyword.term_value : keyword.text;
		},
		hasContent(keyword) {
			const val = this.getContent(keyword);
			return val != null && val.length > 0;
		},
		getHoverColor() {
			return this.hovered ? 'grey' : 'blue';
		},
		getAvatarIcon() {
			return this.hovered ? 'mdi-pencil' : 'mdi-file';
		},
		collapseNoteFunction() {
			this.compactNote = !this.compactNote;
		},
		editNote(item) {
			const id = item.data.data_row_id;
			this.$emit('edit-item', { id: id, type: '5' });
		}
	}
};
</script>

<style scoped>
.title {
	margin-left: 35px;
	font-size: 0.9rem;
	font-weight: bold;
}
.content {
	margin-left: 45px;
}
.note-button:hover {
	background-color: rgba(var(--v-theme-primary),0.1)!important;
}
.note-line:hover {
	cursor: pointer;
}
</style>
