<template>
  <v-list-item class="p-0">
    <div class="date-button">
      <v-toolbar color="transparent" class="date-line ml-2" @click="collapseFunction">
        <v-avatar color="primary">
          <v-icon>mdi-calendar</v-icon>
        </v-avatar>
        <v-toolbar-title>
          <strong class="text-dark user-select-none">{{ formattedDate }}</strong>
        </v-toolbar-title>
        <!-- ICONS -->
        <div v-if="compact" class="mr-3">
          <v-expand-transition appear leave>
            <div class="d-flex">
              <div v-for="(type, index) in dateIcons" :key="index">
                <div v-if="iconAvailable(type)" class="ml-1">
                  <v-tooltip activator="parent" location="top">{{ iconTooltipTranslation(type) }}</v-tooltip>
                  <v-avatar :color="getIcon(type).color" density="compact" size="small">
                    <v-icon v-if="getIcon(type).icon.indexOf('mdi') >= 0" size="x-small" class="fa-avatar">{{ getIcon(type).icon }}</v-icon>
                    <v-icon v-else size="x-small" class="fa-avatar" :class="getIcon(type).icon"></v-icon>
                  </v-avatar>
                </div>
              </div>
            </div>
          </v-expand-transition>
        </div>
        <v-icon v-if="compact" class="mr-3">mdi-unfold-less-horizontal</v-icon>
        <v-icon v-else class="mr-3">mdi-unfold-more-horizontal</v-icon>
      </v-toolbar>
    </div>
    <v-list class="mx-0 px-0 pt-0" v-if="!compact">
      <v-slide-y-transition appear leave>
        <div>
          <journal-unit v-for="(unit, index) in unitDataVariable" 
            class="p-0 m-0"
            :key="index" 
            :journalData="unit" 
            :unit="unit"  
            @edit-item="handleEditItem" 
          />
        </div>
      </v-slide-y-transition>
    </v-list>
  </v-list-item>
  <hr class="p-0 m-0">
</template>

<script>

import JournalUnit from '../journalunit/journalunit.vue';

export default {
  data() {
    return {
      compact: false,
      icons: [
        {'type':'2', 'name': 'diagnose', 'color':'teal-lighten-2', 'icon':'fat fa-person-dots-from-line' },
        {'type':'3', 'name': 'warning', 'color':'blue', 'icon':'fal fa-exclamation-triangle' },
        {'type':'4', 'name': 'lab', 'color':'blue', 'icon':'mdi-microscope' },
        {'type':'5', 'name': 'note', 'color':'blue', 'icon':'mdi-file' },
        {'type':'7', 'name': 'measure', 'color':'green', 'icon':'far fa-scalpel' },
        {'type':'9', 'name': 'attention', 'color':'blue', 'icon':'fal fa-exclamation-triangle' },
        {'type':'9N', 'name': 'attention_n', 'color':'blue', 'icon':'fal fa-exclamation-circle' },
        {'type':'9A', 'name': 'attention_a', 'color':'blue', 'icon':'fal fa-person-dots-from-line' },
        {'type':'9P', 'name': 'attention_p', 'color':'blue', 'icon':'fal fa-person-pregnant' },
        {'type':'9I', 'name': 'attention_i', 'color':'blue', 'icon':'fal fa-virus' },
        {'type':'9L', 'name': 'attention_l', 'color':'blue', 'icon':'fal fa-person-breastfeeding' },
        {'type':'9B', 'name': 'attention_b', 'color':'blue', 'icon':'mdi mdi-water-alert' },
        {'type':'9M', 'name': 'attention_m', 'color':'blue', 'icon':'mdi mdi-screw-round-top' },
        {'type':'16','name': 'prescription', 'color':'blue', 'icon':'mdi-prescription' },
        {'type':'18','name': 'vaccination', 'color':'blue', 'icon':'mdi-needle' }
      ],
      types: []
    }
  },
  mounted() {
    this.compact = this.compactDate === true ? true : false;
    this.types = this.icons.map(val => val.type);
  },
  watch: {
    compactDate() {
      this.compact = this.compactDate === true ? true : false;
    }
  },
  props: {
    journalData: {
      type: Object,
      required: true,
    },
    date: {
      type: String,
      required: true
    },
    compactDate: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    JournalUnit
  },
  computed: {
    formattedDate() {
      const months = [
        'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
        'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
      ];

      const date = new Date(this.date);      
      const day = date.getDate(); 
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const monthName = months[parseInt(month, 10) - 1];
      return `${day} ${monthName} ${year}`;
    },
    unitDataVariable() {
      const grouped = [];
      let id; 
      this.journalData.forEach(item => {
        const unitId = item.data.metadata.unit_id;
        if (!grouped[unitId]) {
          grouped[unitId] = {
            unit_name: item.data.metadata.unit_name,
            owner_name: item.data.metadata.owner_name,
            items: []
          };
        }
        grouped[unitId].items.push(item);
        id = unitId;
      });
      return Object.values(grouped);
    },
    dateIcons() {
      let types = [];
      const subNine = 'NAPILBM'.split('');
      for(let variable of this.unitDataVariable) {
        const items = variable.items;
        for(let item of items) {
          // type 9 has sub categories
          if(item.type === '9') {
            if('data' in item && 'category' in item.data) {
              const category = item.data.category;
              if(subNine.indexOf(category) >= 0) {
                types.push(`9${category}`);
              }
            }
            continue;
          } 
          types.push(item.type);
        }
      }
      return [...new Set(types)];
    }
  },
  methods: {
    iconTooltipTranslation(type) {
      const icon = this.icons.find(val => val.type == type);
      const name = icon.name;
      return this.$t('journal.type.'+name);

    },
    iconAvailable(type) {
      return this.types.indexOf(type) >= 0;
    },
    getIcon(type) {
      return this.icons.find(val => val.type == type);
    },
    handleEditItem(info) {
      this.$emit('edit-item', info);
    },
    collapseFunction() {
      this.compact = this.compact === true ? false : true;
    }
  }
};
</script>
<style scoped>
.date-button:hover {
  background-color: rgba(var(--v-theme-primary),0.2)!important;
}
.date-line:hover {
  cursor: pointer;
}
</style>
