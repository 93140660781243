<template>
    <v-dialog v-model="internalModalState" max-width="500px" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">{{ $t('journal.modals.delete_title') }}</span>
            </v-card-title>
            <v-card-text>
                {{ $t('journal.modals.delete_message') }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="handleConfirm">{{ $t('journal.modals.ok') }}</v-btn>
                <v-btn color="red darken-1" text @click="handleCancel">{{ $t('journal.modals.cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isModalOpen: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            internalModalState: this.isModalOpen
        };
    },
    watch: {
        isModalOpen(val) {
            this.internalModalState = val;
        },
        internalModalState(val) {
            this.$emit('update:isModalOpen', val);
        }
    },
    methods: {
        handleConfirm() {
            this.$emit('confirm');
        },
        handleCancel() {
            this.$emit('cancel');
        }
    }
};
</script>
