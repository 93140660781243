<template>
  <v-container class="p-0">
    <term-name v-model="termName" />
    <v-textarea
      v-model="termValue"
      v-signal-auto-correct="termValue"
      density="compact"
      variant="outlined"
      rows="1"
      auto-grow
      hide-details
      @update:modelValue="onTermValueChange"
    />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'UnformattedText',
  components: {
    TermName
  },
  props: {
    keywordData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const termName = ref('');
    const termValue = ref('');

    watch(
      () => props.keywordData,
      (newVal) => {
        if (newVal) {
          termName.value = newVal.term_name || termName.value;
          termValue.value = newVal.term_value || termValue.value;
        }
      },
      { immediate: true }
    );

    watch(termName, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_name: newVal });
    });

    const onTermValueChange = (newValue) => {
      emit('update:keywordData', { ...props.keywordData, term_value: newValue });
    };

    return {
      termName,
      termValue,
      onTermValueChange
    };
  }
});
</script>
