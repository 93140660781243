<template>
    <table class="table table-striped">
        <thead>
        <tr>
            <td><strong>{{ $t('settings.count_units') }}</strong></td>
            <td>
                <check-box-button v-model="include_o" @input="toggleOption('include_o', include_o)" class="w-10">{{ $t('settings.count') }}</check-box-button>
            </td>
        </tr>
        <tr>
                <td><strong>{{ $t('settings.sound_with_notifications') }}</strong></td>
                <td>
                    <check-box-button v-model="muteSound" @input="toggleOption('mute_alert', muteSound)" class="w-10">{{ $t('settings.sound') }}</check-box-button>
                </td>
        </tr>
        <tr>
            <td><strong>{{ $t('settings.setting') }} 4</strong></td>
            <td>{{ $t('settings.setting') }} 4</td>
        </tr>
    </thead>
    </table>
        
</template>
<script>
import SettingService from '@src/api/setting';
import localService from '@src/api/local';
import CheckBoxButton from '../checkBoxButton';

export default {
    components: {
        CheckBoxButton,
        
    },
    data() {
        return {
            include_o: 1,
            muteSound: 1,
            };
    },
    mounted() {

        this.getSetting('muteSound', 'mute_alert');
        this.getSetting('include_o', 'include_o');
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        toggleOption(slug, bool) {
            SettingService.toggleBoolSetting(slug, bool);
        },
        getSetting(var_name, slug) {
            SettingService.getSettingsValueBySlug(slug).then(res => {
                this[var_name] = Boolean(res);
            });
        }
    }
}
</script>
