<template>
    <v-card flat>
        <v-toolbar color="transparent">
            <v-avatar
                density="compact"
                :color="getHoverColor()" 
                @mouseenter="hovered = true"
                @mouseleave="hovered = false"
            >
                <v-icon :class="getAvatarIconClass()" size="small"></v-icon>
            </v-avatar>
            <v-toolbar-title>
                <span class="font-weight-bold">{{ journalData.data.name }} </span>
                <v-chip v-if="journalData.data.code && journalData.data.code.length > 0" size="small" class="ml-3">
                    {{ journalData.data.code }}
                </v-chip>
            </v-toolbar-title>
            <v-chip prepend-icon="mdi-doctor" size="small" class="text-0">{{ journalData.data.metadata.owner_name }}</v-chip>
        </v-toolbar>
        <v-card-text v-if="journalData.data.comment" class="journal-indent pt-0 mb-0">
            <p class="mb-0">{{ journalData.data.comment }}</p>
    </v-card-text>
</v-card>
</template>

<script>
export default {
    props: {
        journalData: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            hovered: false,
            mdiPencil: 'mdi-pencil',
        };
    },
    computed: {
        transformedMeasureItems() {
            return this.measureItems.map(item => ({
                name: item.data.name,
                code: item.data.code,
                comment: item.data.comment,
                owner_name: item.data.metadata.owner_name,
            }));
        }
    },
    mounted() {
    },
    methods: {
        getHoverColor() {
            return 'green';
        },
        getAvatarIcon() {
            return 'far fa-scalpel';
        },
        getAvatarIconClass() {
            return 'far fa-scalpel';
        }
    }
};
</script>
<style scoped>
.journal-indent {
    margin-left: 40px;
}
</style>
