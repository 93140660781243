<template>
    <table class="table table-hover table-responsive-md">
        <thead>
            <tr>
                <th> {{ t('invoice_create.service_recipient') }}</th>
                <th>{{ t('invoice_create.ssn') }}</th>
                <th>{{ t('invoice.invoice') }}</th>
                <th>{{ t('invoice_create.cash_register') }}</th>
                <th>{{ t('doctor_dashboard.doctor') }}</th>
                <th class="text-right">{{ t('telemedicine.for_payment') }}</th>
                <th />
            </tr>
        </thead>
        <tbody>
            <tr v-if="invoices.length === 0">
                <td colspan="7" class="text-center">{{ t('invoice.no_invoice_found') }}</td>
            </tr>
            <tr v-for="(invoice, index) in invoices" :key="index" :class="{'bg-success' : invoice.printed == '1'}">
                <td><i class="fal fa-user" /> {{ invoice.patient && invoice.patient.name }}</td>
                <td>{{ invoice.patient && $filters.codenumber(invoice.patient.codenumber) }}</td>
                <td>{{ invoice.invoice_id }}</td>
                <td>{{ cashregisters.find(cr => cr.id == invoice.cash_register_id).name }}</td>
                <td>{{ invoice.doctor && invoice.doctor.name }}</td>
                <td class="text-right"><strong>{{ $filters.currency($filters.scale(invoice.patient_share)) }}</strong></td>
                <td class="text-right">
                    <button class="btn btn-primary" title="Afgreiða reikning" @click.stop="emitSelected(invoice)">
                        {{ buttonTxt(invoice) }}
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        invoices: {
            required: true,
            type: Array
        },
        cashregisters: { // Required for lookup
            required: true,
            type: Array
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        emitSelected(invoice) {
            this.$emit('selected', invoice);
        },
        // buttonTxt(invoice) {
        //     return invoice.printed == '1' ? 'Endurafgreiða' : 'Klára reikning';
        // }
        buttonTxt(invoice) {
            return invoice.printed === '1'
                ? this.$t('reception.reprocess')
                : this.$t('invoice.complete_invoice');
        }

    }
};
</script>
