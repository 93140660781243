<template>
  <v-container class="p-0">
    <term-name v-model="termName" />
    <v-combobox
      v-model="termValues"
      :items="itemValues"
      density="compact"
      variant="outlined"
      multiple
      clearable
      chips
      hide-details
      @update:modelValue="onTermValuesChange"
    />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import KeywordValue from '@src/api/keywordvalue';

export default {
  name: 'MultipleValues',
  components: {
    TermName
  },
  props: {
    keywordData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      termName: 'Who requested the admission',
      termValues: [],
      itemValues: []
    };
  },
  watch: {
    keywordData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.termName = newVal.term_name || this.termName;
          this.termValues = newVal.term_value
            ? newVal.term_value.split(',').map(item => item.trim())
            : [];
          if (newVal.term_id) {
            this.fetchItems(newVal.term_id);
          }
        }
      }
    },
    termName(newVal) {
      this.$emit('update:keywordData', { ...this.keywordData, term_name: newVal });
    }
  },
  methods: {
    async fetchDataWithParams(api, params) {
      try {
        const response = await api.index(params);
        return response.data;
      } catch (error) {
        console.error('Error in fetchDataWithParams:', error);
        return [];
      }
    },
    async fetchItems(term_id) {
      try {
        const response = await this.fetchDataWithParams(KeywordValue, { term_id });
        if (Array.isArray(response)) {
          this.itemValues = response.map(item => item.term_value);
        } else if (response && Array.isArray(response.data)) {
          this.itemValues = response.data.map(item => item.term_value);
        } else {
          console.error('KeywordValueData is not in the expected format', response);
          this.itemValues = [];
        }
        this.termValues = this.keywordData.term_value
          ? this.keywordData.term_value.split(',').map(item => item.trim())
          : [];
      } catch (error) {
        console.error('Error fetching data:', error);
        this.itemValues = [];
        this.termValues = [];
      }
    },
    onTermValuesChange(newValues) {
      this.$emit('update:keywordData', { ...this.keywordData, term_value: newValues.join(',') });
    }
  },
  mounted() {
    if (this.keywordData && this.keywordData.term_id) {
      this.fetchItems(this.keywordData.term_id);
    }
  }
};
</script>

