<template>
    <div 
        class="card mb-2" 
        :class="{ 'border-info': !exception, 'border-warning': exception }"
    >
        <div 
            class="card-header" 
            :class="{ 'bg-info text-white border-info': !exception, 'bg-warning': exception}"
        >
            <i 
                class="fal" 
                :class="{ 'fa-tablets': !exception, 'fa-prescription-bottle': exception }" 
            /> 
            {{ exception ? 'Undanþágulyf' : 'Lyf' }}
            <transition 
                name="fade-fast" 
                mode="out-in"
            >
                <span 
                    v-if="removable" 
                    class="close pull-right" 
                    @click="onCloseClicked"
                >
                    &times;
                </span>
            </transition>
        </div>
        <div class="card-body border-warning">
            <div class="row">
                <div class="col-lg-8 md-6">
                    <search 
                        ref="select" 
                        :error="errors.medicine" 
                        :tabindex="String(tabIndexStart + 1)" 
                        @selected="onSelected" 
                        @deselected="onDeSelected"
                    />
                </div>
                <div class="col-lg-2 col-md-3 mb-sm-6 mb-2">
                    <button 
                        type="button" 
                        class="btn btn-outline-secondary btn-block" 
                        @click="onSynonymSearchClicked"
                    >
                        <i class="fal fa-search" />
                        {{ t('prescription.combination_drug') }}
                    </button> 
                </div>
                <div class="col-lg-2 col-md-3 mb-sm-6">
                    <div class="form-group">
                        <button 
                            type="button" 
                            class="btn btn-outline-info btn-block" 
                            @click="onInfoClicked"
                        >
                            <i class="fal fa-info-circle" /> {{ t('prescription.information') }}
                        </button>
                    </div>
                </div>
            </div>
            <div 
                v-if="selected" 
                class="row"
            >
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ t('prescription.Drug_name') }}</label>
                        <div class="input-group">
                            <div 
                                class="form-control" 
                                :class="{'non-form-info': !exception, 'non-form-warning': exception}"
                            >
                                {{ selected.name }}
                            </div>
                            <div class="input-group-append">
                                <check-box-button 
                                    v-model="r" 
                                    :tabindex="String(tabIndexStart + 5)"
                                >R</check-box-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ t('prescription.drug_form') }}</label>
                        <div 
                            class="form-control" 
                            :class="{'non-form-info': !exception, 'non-form-warning': exception}"
                        >
                            {{ selected.form }}
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ t('prescription.strength') }}</label>
                        <div 
                            class="form-control" 
                            :class="{'non-form-info': !exception, 'non-form-warning': exception}"
                        >
                            {{ selected.strength }} {{ selected.strength_unit }}
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label>{{ t('prescription.prescribed_amount') }}</label>
                        <div 
                            class="form-control" 
                            :class="{'non-form-info': !exception, 'non-form-warning': exception}"
                        >
                            {{ prescribedAmount }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-lg-4 col-md-6 col-sm-8">
                    <text-input 
                        v-model="shortDosage" 
                        v-tooltip="t('prescription.content_will_not_appear_on_prescription')"
                        v-bind:label="t('prescription.dosage')" 
                        :tabindex="String(tabIndexStart + 2)" 
                        :error="errors.short_dosage && shortDosage.length > 20"
                        @change="onShortDosageChange" 
                        @key.enter.prevent.stop=""
                    />
                    <span 
                        v-if="shortDosage.length > 20"
                        :class="{'text-danger':errors.short_dosage}" 
                    >
                    {{ t('prescription.Max_length_of_dosage') }} ({{ shortDosage.length }})
                    </span>
                </div>
                <div class="col-md-6 col-sm-4">
                    <label class="d-none d-sm-inline-block">&nbsp;</label><br class="d-none d-sm-block">
                    <check-box-button 
                        v-model="whenNecessary" 
                        :tabindex="String(tabIndexStart + 3)" 
                        @input="onWhenNecessary"
                    >{{ t('prescription.as_needed') }}</check-box-button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <text-input-area 
                        v-model="instructions" 
                        :required="true" 
                        :error="errors.instructions"
                        v-bind:label="t('prescription.usage_descriptions')"
                        :tabindex="String(tabIndexStart + 4)"
                        @input="clearError('instructions')"
                    />
                </div>
                <div class="col-12">
                    <text-input 
                        v-model="indication" 
                        v-bind:label="t('prescription.drug_used_for')" 
                        :tabindex="String(tabIndexStart + 6)" 
                    />
                </div>
            </div>
            <div class="row align-items-end">
                <div class="col-lg-2">
                    <text-input-number 
                        v-model="numPackages" 
                        :min="0"
                        :required="true" 
                        :error="errors.number_of_packages" 
                        v-bind:label="t('prescription.no_of_packages')" 
                        :tabindex="String(tabIndexStart + 7)"
                        @input="clearError('number_of_packages')"
                    />
                </div>
                <div class="col-lg-7">
                    <div class="form-group">
                        <label>{{ t('cash_registers.delivery') }}</label>
                        <div class="d-md-flex d-row">
                            <div class="m-0 border rounded-0 input-group-text text-center">
                                {{ t('cash_registers.disbursed') }}
                            </div>
                            <div class="m-0">
                                <select 
                                    v-model="iterations" 
                                    class="custom-select text-center rounded-0" 
                                    :tabindex="String(tabIndexStart + 8)"
                                >
                                    <!-- eslint-disable-next-line -->
                                    <option value="1" selected>1  {{ t('cash_registers.times') }}</option>
                                    <option value="2">2 {{ t('cash_registers.times') }}</option>
                                    <option value="3">3 {{ t('cash_registers.times') }}</option>
                                    <option value="4">4 {{ t('cash_registers.times') }}</option>
                                </select>
                            </div>
                            <div 
                                v-if="iterations > 1" 
                                class="m-0 border rounded-0 text-center input-group-text"
                            >
                            {{ t('cash_registers.at_least') }}
                            </div>
                            <div 
                                v-if="iterations > 1" 
                                class="m-0"
                            >
                                <input 
                                    v-model="interval.value" 
                                    type="text" 
                                    class="form-control text-center" 
                                    
                                    :disabled="iterations <= 1" 
                                    :tabindex="String(tabIndexStart + 9)"
                                >
                            </div>
                            <div 
                                v-if="iterations > 1" 
                                class="m-0"
                            >
                                <select 
                                    v-model="interval.unit" 
                                    :disabled="iterations <= 1" 
                                    class="custom-select text-center rounded-0"
                                    :tabindex="String(tabIndexStart + 10)"
                                >
                                    <option value="dagar"> {{ t('prescription.days') }}</option>
                                    <option value="vikur"> {{ t('prescription.weeks') }}</option>
                                    <option value="mánuðir">{{ t('prescription.months') }}</option>
                                </select>
                            </div>
                            <div 
                                v-if="iterations > 1" 
                                class="m-0 border rounded-0 text-center input-group-text"
                            >
                            {{ t('prescription.deadline') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <check-box-button 
                            v-model="dosebox" 
                            :tabindex="String(tabIndexStart + 11)"
                        > {{ t('prescription.dispensed') }}</check-box-button>
                    </div>
                </div>
            </div>           
        </div>
        <div 
            v-if="exception" 
            class="card-body bg-warning"
        >
            <div class="row">
                <div class="col-12">
                    <text-input-area 
                        v-model="argument" 
                        :required="true" 
                        :error="errors.arguments" 
                        v-bind:label="t('prescription.reason_for_exemption')"
                        @input="clearError('arguments')"
                    />
                    <check-box 
                        v-model="terms" 
                        :required="true" 
                        :error="errors.conditions" 
                        @input="clearError('conditions')"
                    >
                    {{ t('prescription.prescriber_of_drug') }}
                    </check-box>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import localService from '../../../../api/local.js';
import Search from './search';
import TextInput from '../../../textInput';
import TextInputArea from '../../../textInputArea';
import TextInputNumber from '../../../textInputNumber';
import CheckBox from '../../../checkBox';
import CheckBoxButton from '../../../checkBoxButton';
import ShortDosageParser from '../../../../classes/shortDosageParser';

export default {
    components: {
        Search,
        TextInput,
        TextInputArea,
        TextInputNumber,
        CheckBox,
        CheckBoxButton,
    },
    props: {
        // eslint-disable-next-line 
        preSelected: {
            type: Object,
            required: false,
        },
        removable: { 
            type: Boolean,
            required: false, 
            default: true 
        },
        forms: {
            type: Array,
            required: false,
            default: () => []
        },
        expressions: {
            type: Array,
            required: false,
            default: () => []
        },
        tabIndexStart: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            selected: undefined,
            iterations: 1,
            interval: {
                value: 30,
                unit: 'dagar'
            },
            indication: '',
            numPackages: 0,
            shortDosage: '',
            whenNecessary: false,
            dosebox: false,
            r: false,
            instructions: '',
            argument: '',
            terms: false,
            errors: {
                medicine: false,
                instructions: false,
                number_of_packages: false,
                conditions: false,
                arguments: false,
                short_dosage: false,
            }
        };
    },
    computed: {
        ...mapGetters([
            'currentPatientName',
            'currentPatientCodenumber'
        ]),
        exception() {
            return this.selected !== undefined && this.selected.exception === '1';
        },
        nordic_id() {
            return this.selected !== undefined ? this.selected.nordic_id : '';
        },
        requestData() {
            let request = {
                medicine: this.nordic_id,
                iterations: this.iterations,
                interval: this.interval,
                number_of_packages: this.numPackages,
                instructions: this.instructions,
                substitution: !this.r,
                when_necessary: this.whenNecessary,
                dosebox: this.dosebox,
                short_dosage: this.shortDosage,
                indication: this.indication
            };

            if (this.exception) {
                request.exception = true;
                request.conditions = this.terms;
                request.arguments = this.argument;
            }

            return request;
        },
        prescribedAmount() {
            if ( this.selected.amount > 0 && this.numPackages > 0 ) {
                let amount = this.selected.amount * this.numPackages;
                return `${amount} ${this.selected.amount_unit}`;
            } else {
                return '0';
            }
        }
    },
    mounted() {
        if (this.preSelected) {
            localService.getMedicene(this.preSelected.medicine.nordic_id).then(response => this.selected = response.data);
            this.shortDosage = this.preSelected.short_dosage;
            this.instructions = this.preSelected.instruction;
            let temp = this.preSelected.iteration.split('.');
            this.iterations = temp[0];
            this.numPackages = this.preSelected.number_of_packages;
        }
    },    
    methods: {
        t(key) {
            return this.$t(key);
        },
        onCloseClicked() {
            this.$emit('close');
        },
        onShortDosageChange() {
            if (this.selected !== undefined) {
                const parser = new ShortDosageParser(this.expressions, this.forms);
                if (this.instructions.trim().length === 0) {
                    this.instructions = parser.parse(this.shortDosage, this.selected.form);
                    this.clearError('instructions');
                }
            }
            if (this.shortDosage.length <= 20) {
                this.clearError('short_dosage');
            }
        },
        onSelected(item) {
            this.clearError('medicine');
            this.selected = item;
            this.numPackages = item.number_of_packages;
        },
        onDeSelected() {
            this.selected = undefined;
            this.numPackages = 0;
        },
        onWhenNecessary() {
            if (this.whenNecessary) {
                if (this.instructions === '') {
                    this.instructions =  this.t('prescription.as_needed');
                } else if (! /.*eftir þörfum.*/i.test(this.instructions)) {
                    this.instructions = this.instructions.trim() + ` ${this.t('prescription.as_needed')}`;
                }
                this.clearError('instructions');
            } else {
                this.instructions = this.instructions.replace(/\s?eftir þörfum$/i, '');
            }
        },
        onSynonymSearchClicked(e) {
            e.stopPropagation();
            this.$refs.select.synonymSearch();
        },
        onInfoClicked() {
            if (this.selected !== undefined) {
                this.$emit('help', this.selected.atc);
            } else {
                this.$emit('help');
            }
        },
        setError(error) {
            this.errors[error] = true;
        },
        clearError(error) {
            this.errors[error] = false;
        }
    }
};
</script>
