<template>
  <v-card flat>
    <v-toolbar color="transparent">
      <div>
        <!-- read only for now -->
        <!-- <div @mouseover="hovered = true" @mouseleave="hovered = false"> -->
        <v-avatar :color="getAvatarColor(journalData.data.lab.Priority)" density="compact">
          <!-- read only for now -->
          <!-- <v-icon @click="editLabResult(journalData.data)" size="small"> -->
          <v-icon size="small">
            {{ hovered ? 'mdi-pencil' : 'mdi-microscope' }}
          </v-icon>
        </v-avatar>
      </div>
      <v-toolbar-title>
        <strong>{{ $t('journal.editor.labresult.lab_result') }}</strong>
      </v-toolbar-title>
      <v-chip v-if="journalData.data.status && journalData.data.status !== ''" size="small" class="ml-2 text-caption">
        {{ journalData.data.status }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-chip prepend-icon="mdi-doctor" size="small" class="text-1 user-select-none">{{ journalData.user }}</v-chip>
    </v-toolbar>
    <v-card-text v-if="journalData.data.observer_comment && journalData.data.observer_comment !== ''"
      class="journal-indent pt-0 text-grey-darken-">
      <p class="text-grey mb-2">
        {{ journalData.data.observer_comment }}
      </p>
    </v-card-text>
    <v-card-text v-for="sample in journalData.data.lab_result_group" :key="sample.specimen" class="pt-0 text-grey-darken">
      <div class="d-flex align-items-center journal-indent">
        <h5 class="my-auto">{{ sample.specimen }}</h5>
        <v-chip size="small" class="ml-3 text-caption user-select-none">
          {{ formatDate(sample.sample_time) }}
        </v-chip>
      </div>
      <p v-if="sample.group_comment && sample.group_comment !== ''" class="text-grey mb-2">
        {{ sample.group_comment }}
      </p>
      <v-table density="compact" class="table-indent">
        <thead>
          <tr>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.analysis') }}</th>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.result') }}</th>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.range') }}</th>
            <th style="font-weight: bold;">{{ $t('journal.editor.labresult.comment') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(resultItem, resultIndex) in sample.items" :key="resultIndex">
            <td>{{ resultItem.analysis }}</td>
            <td>{{ resultItem.result }} {{ resultItem.unit }}</td>
            <td>{{ resultItem.range }}</td>
            <td>{{ resultItem.analysis_comment }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    journalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    getAvatarColor(priority) {
      return this.hovered ? 'grey' : priority === 'S' ? 'orange' : 'blue';
    },
    editLabResult(item) {

    },
    formatDate(value) {
      const locale = this.$t('language.locale_name');
      return new Date(value).toLocaleDateString(locale);
    },
    translatedStatus(status) {
      return this.$t(`labresult.${status.trim()}`);
    },
  },
};
</script>
<style scoped>
.journal-indent {
    margin-left: 40px;
}
.table-indent {
    margin-left: 25px;
}
</style>
