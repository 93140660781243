import { defineStore } from "pinia";

export const useSidebarsStore = defineStore("sidebars", {
  state: () => ({
    showInvoiceSidebar: false,
    showPatientSidebar: false,
    showJournalSettingsSidebar: false,
    typesFromJournalStore: [2, 3, 4, 5, 7, 9, 13, 16, 18],
    sortFromJournalStore: 'desc',
  }),
  actions: {
    // Setter for showInvoiceSidebar
    setShowInvoiceSidebar(value) {
      this.showInvoiceSidebar = value;
    },
    // Setter for showJournalSettingsSidebar
    setShowJournalSettingsSidebar(value) {
      this.showJournalSettingsSidebar = value;
    },
    getJournalSettingsSidebarState() {
      return this.showJournalSettingsSidebar;
    },
    setShowPatientSidebar(value) {
      this.showPatientSidebar = value;
    },
    toggleShowPatientSidebar() {
      this.showPatientSidebar = !this.showPatientSidebar;
    },
    openPatientSidebar() {
      this.showPatientSidebar = true;
    },
    closePatientSidebar() {
      this.showPatientSidebar = false;
    },
    setTypesFromJournalStore(types) {
      this.typesFromJournalStore = types;
    },
    setSortFromJournalStore(value) {
      console.log(value, "value");
      this.sortFromJournalStore = value;
    },
    // Add resetSettings
    resetSettings() {
      this.typesFromJournalStore = [2, 3, 4, 5, 7, 9, 13, 16, 18];
      this.sortFromJournalStore = 'desc';
      console.log("Settings have been reset:", {
        types: this.typesFromJournalStore,
        sortOrder: this.sortFromJournalStore,
      });
    },
  },
});
