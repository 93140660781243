<template>
    <li 
        class="list-group-item user-select-none"
        :class="{
            'active': highlighted,
            'list-group-item-warning': exception,
            'clickable': clickable,
        }"
        @click="$emit('click')"
    >
        <span class="badge mr-2" :class="{ 'badge-primary': !exception, 'badge-warning': exception }">
            {{ medicine.atc }}
        </span>
        <span v-if="medicine.addictive > 0" :title="$t('drug_database.addictive_drugs')">
            <i class="fas fa-exclamation-triangle text-danger mr-1" />
        </span>
        <!-- eslint-disable-next-line -->
        <span v-html="highlightedName()" /> 
        {{ medicine.strength }} {{ medicine.strength_unit }} <span class="text-muted mx-1">{{ $filters.medicineForm(medicine.form) }} / {{ medicine.amount }} {{ medicine.amount_unit }} {{ medicine.package_unit }} </span>
    </li>
</template>
<script>
export default {
    props: {
        medicine: {
            required: true,
            type: Object
        },
        highlighted: {
            required: false,
            type: Boolean,
            default: false
        },
        query: {
            required: false,
            type: String,
            default: ''
        },
        clickable: {
            required: false,
            type: Boolean,
            default: false
        },
    },
    computed: {
        exception() {
            return this.medicine.exception === '1';
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        highlightedName() {
            return this.query !== '' ? this.medicine.name.replace(new RegExp(this.query, 'gi'), '<b>$&</b>') : this.medicine.name;
        }
    }
};
</script>
<style lang="scss" scoped>
    .clickable:hover {
        cursor: pointer;
        opacity: .6;
    }
</style>
