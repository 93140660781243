<template>
  <v-container fluid class="nopatient">
    <v-row class="justify-center ml-4">
      <v-col cols="12">        
        <h3 class="text-center">
          <v-icon color="grey" size="large">mdi mdi-account-alert</v-icon>
        </h3> 
        <p class="text-grey text-center user-select-none">{{ $t('patient.no_patient_selected') }}</p>
        <p class="text-grey text-center user-select-none"><v-btn prepend-icon="mdi-account-search" color="primary" @click="sidebarsStore.openPatientSidebar()">{{ $t('patient.find_patient') }}</v-btn></p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { useSidebarsStore } from '@src/stores/SidebarsStore';
export default {
  data() {
    return {
      sidebarsStore: useSidebarsStore()
    }
  }
}
</script>
<style scoped>
.nopatient {
  margin: 100px 50px 0 0;
}
</style>
