<template>
  <v-navigation-drawer v-model="showJournalSettingsSidebar" location="left" :permanent="true" :width="drawerWidth">
    <v-toolbar>
      <v-app-bar-nav-icon icon="mdi-chevron-left" @click="closeSidebar"></v-app-bar-nav-icon>
      <v-toolbar-title class="user-select-none">{{ $t('journal.setting.setting') }}</v-toolbar-title>
      <template v-slot:append>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
    <v-list>
      <v-list-subheader>{{ $t('journal.setting.sort_by') }}</v-list-subheader>
      <v-list-item :title="(store.sortFromJournalStore === 'desc') ?  $t('journal.setting.most_recent_first')  :  $t('journal.setting.most_recent_last') "
        :append-icon="(store.sortFromJournalStore === 'desc') ? 'mdi-sort-calendar-descending' : 'mdi-sort-calendar-ascending'"
        @click="changeSortOrder()" />
    </v-list>
    <v-list>
      <v-list-subheader>{{ $t('journal.setting.minimize') }}</v-list-subheader>
      <v-list-item>
        <v-container fluid class="py-0">
          <v-switch v-model="compact" color="primary" value="minimized-days" hide-details>
            <template #label>
              <div class="mt-2 text-dark">{{ $t('journal.setting.minimize_days') }}</div>
            </template>
          </v-switch>
          <v-switch v-model="compact" color="primary" value="minimized-notes" hide-details>
            <template #label>
              <div class="mt-2 text-dark">{{ $t('journal.setting.minimize_notes') }}</div>
            </template>
          </v-switch>
        </v-container>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-subheader>{{ $t('journal.setting.filter') }}</v-list-subheader>
      <v-list-item v-for="type in types" :key="type.moduleId" :active="!isFiltered(type.moduleId)"
        :prepend-icon="type.icon" @click="changeFilter(type.moduleId)" :value="type.moduleId">
        <template #title>
          <div class="text-dark">{{ getFilterTypeTranslation(type.name) }}</div>
        </template>
      </v-list-item>
    </v-list>
    <v-list class="mt-4">
      <v-list-subheader>{{ $t('journal.setting.external_data') }}</v-list-subheader>
      <v-list-item v-for="(service, index) in externalServices" :title="service.name" :active="service.active"
        :prepend-icon="(service.active) ? 'mdi-server-network' : 'mdi-server-network-off'" :key="index"
        @click="service.active = !service.active" />
    </v-list>
  </v-navigation-drawer>
</template>


<script setup>

import localApi from '@src/api/local';
import { ref, computed, onMounted, watch } from 'vue';
import { useSidebarsStore } from '@stores/SidebarsStore';
import { useUserSettingStore } from '@stores/userSettingStore';
import { useJournalStore } from '@stores/journalStore';
import { useI18n } from 'vue-i18n';

const store = useSidebarsStore();
const userStore = useUserSettingStore();
const journalStore = useJournalStore();

let sortOrder = 'desc'
let compact = ref([]);
const { t } = useI18n();

onMounted(() => {
  // update compact on patient change 
  userStore.$subscribe((mutation) => {
    if (mutation.storeId === 'userSetting') {
      const updatedSettings = userStore.getJournalSettingsCompactSettings();
      const empty = !updatedSettings.days && !updatedSettings.notes;
      compact.value = empty === true ? [] : compact.value;
    }
  });

  const settings = userStore.getJournalSettingsCompactSettings();
  if (settings.days && compact.value.indexOf('minimized-days') < 0) {
    compact.value.push('minimized-days');
  }
  if (settings.notes && compact.value.indexOf('minimized-notes') < 0) {
    compact.value.push('minimized-notes');
  }
});

watch(compact, () => {
  const days = compact.value.indexOf('minimized-days') >= 0 ? '1' : '0';
  const notes = compact.value.indexOf('minimized-notes') >= 0 ? '1' : '0';

  [
    { 'slug': 'journal_days_compact_mode', 'value': days },
    { 'slug': 'journal_notes_compact_mode', 'value': notes }
  ].map(item => {
    localApi.updateUserSetting(item.slug, item.value)
      .then(() => { userStore.updateValue(item.slug, item.value); })
      .catch(err => console.log({ 'error': err }));
  });
});

const changeSortOrder = () => {
  if (store.sortFromJournalStore === 'desc') {
    store.sortFromJournalStore = 'asc';
  } else {
    store.sortFromJournalStore = 'desc';
  }
  console.log('Sort order updated:', store.sortFromJournalStore);
};

const types = [
  { name: 'Attention', moduleId: 9, icon: 'mdi-alert' },
  { name: 'Diagnose', moduleId: 2, icon: 'mdi-human' },
  { name: 'Lab', moduleId: 4, icon: 'mdi-microscope' },
  { name: 'Measure', moduleId: 7, icon: 'mdi-box-cutter' },
  // { name: 'Measurement', moduleId: 13, icon: 'mdi-ruler' },
  { name: 'Note', moduleId: 5, icon: 'mdi-file' },
  { name: 'Prescription', moduleId: 16, icon: 'mdi-prescription' },
  { name: 'Vaccination', moduleId: 18, icon: 'mdi-needle' },
  { name: 'Warning', moduleId: 3, icon: 'mdi-alert' },
]

const getFilterTypeTranslation = (type) => {
  const key = 'journal.type.' + type.toLowerCase();
  const tr = t(key);
  return tr.charAt(0).toUpperCase() + tr.slice(1);
}

const changeFilter = (moduleId) => {
  const typesFromJournalStore = store.typesFromJournalStore;

  if (typesFromJournalStore.includes(moduleId)) {
    store.setTypesFromJournalStore(typesFromJournalStore.filter((id) => id !== moduleId));
  } else {
    store.setTypesFromJournalStore([...typesFromJournalStore, moduleId]);
  }
};

const isFiltered = (moduleId) => !store.typesFromJournalStore.includes(moduleId);

let external = ref(false)
let externalServices = [{ name: 'Samtengt sjúkraskrá', active: false }]
const showJournalSettingsSidebar = computed({
  get: () => store.showJournalSettingsSidebar,
  set: (value) => store.setShowJournalSettingsSidebar(value),
});

let drawerWidth = ref(300)

const closeSidebar = () => {
  showJournalSettingsSidebar.value = false;
};
</script>
