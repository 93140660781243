<template>
  <v-card flat>
    <v-toolbar color="transparent">
      <v-avatar class="buttonHover" @mouseenter="hovered = true" @mouseleave="hovered = false" :color="getHoverColor(index)" density="compact">
        <v-icon size="small">{{ getAvatarIcon(index) }}</v-icon>
      </v-avatar>
      <v-toolbar-title>
        <strong class="user-select-none">{{ journalData.data.name }}</strong>
        <v-chip v-if="journalData.data.atc && journalData.data.atc != ''" class="ml-3" size="small">
          {{ journalData.data.atc }}
        </v-chip>
      </v-toolbar-title>
      <v-chip prepend-icon="mdi-doctor" size="small" class="text-0 user-select-none">{{ journalData.user }}</v-chip>
    </v-toolbar>
    <div class="journal-indent">
      <p class="mb-0">
          <span class="pt-0 title">{{ $t('journal.view.vaccination_disease') }}:</span>
          <span class="pt-0 ml-2">{{ journalData.data.disease }}</span>
      </p>
      <p class="mb-0">
        <span class="pt-0 title">{{ $t('journal.view.vaccination_administration') }}:</span>
        <span class="pt-0 ml-2"> {{ journalData.data.administration }}</span>
      </p>
      <p class="mb-0">
        <span class="pt-0 title">{{ $t('journal.view.vaccination_location') }}:</span>
        <span class="pt-0 ml-2"> {{ journalData.data.location }}</span>
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    journalData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      hovered: false,
      vaccinationItems: [],
      hoverColor: 'blue',
      avatarIcon: 'mdi-needle',
      hoveredItemIndex: null,
    };
  },
  computed: {
    transformedVaccinationItems() {
      return this.vaccinationItems.map(item => ({
        name: item.data.name,
        atc: item.data.atc,
        owner_name: item.data.metadata.owner_name,
        disease: item.data.disease,
        administration: item.data.administration,
        location: item.data.location,
      }));

    }
  },
  mounted() {
    this.vaccinationItems = this.journalData;
  },
  methods: {
    getHoverColor() {
      return this.hovered  ? 'grey' : 'blue';
    },
    getAvatarIcon() {
      return this.hovered ? 'mdi-pencil' : 'mdi-needle';
    }
  }
};
</script>

<style scoped>
.journal-indent {
  margin-left: 52px;
}
.buttonHover:hover {
  cursor: pointer;
}
.title {
  font-size: 0.9rem;
  font-weight: bold;
}
</style>
