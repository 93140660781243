<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <Agenda />
      </v-col>
    </v-row>    
  </v-container>
</template>
<script>

import Agenda from './agenda/agenda.vue'

export default {
  components: { Agenda }
};
</script>
<style scoped>
.container {
  width: 100%;
  overflow: auto;
}
</style>
