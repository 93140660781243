<template>
    <div ref="scrollTop" class="scroll-top"></div>
    <v-container v-if="show" class="mt-5 p-0 px-2 overflow-y-none" :style="{ height: this.formHeight.toString() + 'px' }">
        <v-card>
            <v-toolbar color="blue">
                <v-app-bar-nav-icon icon="mdi-email-newsletter" color="white" />
                <v-toolbar-title class="title text-small user-select-none">
                    {{ title }}
                </v-toolbar-title>
                <!-- icons are in the wrapper -->
                <Wrapper 
                    v-if="this.kindOfSheet && !preview" 
                    :debug="false" 
                    :preview="preview" 
                    :kind="kindOfSheet" 
                    :address="address" 
                    :form="jsonParsed" 
                    :data="formValues" 
                    :storeDisabled="!isFormValid" 
                    :fromHeader="true"
                    @dobMissing="setDobMissing"
                    @store="storeAndSend" 
                    @close="close" 
                />
            </v-toolbar>
            <v-card-text class="px-0 my-0 py-0">
                <div v-if="!loaded && !preview" class="d-flex justify-content-start align-items-start col-12">
                    <v-skeleton-loader class="skeleton-loader mt-4" :type="'heading, subtitle, '.repeat(10)" />
                </div>
                <v-form v-else>
                    <v-container class="overflow-y-scroll" :style="{ height: this.innerFormHeight.toString() + 'px' }">
                        <!-- DATE OF BIRTH MISSING --> 
                        <v-banner
                            v-if="dobMissing"
                            class="text-center mx-auto"
                            style="max-width:500px"
                            color="error"
                            icon="$info"
                            :text="$t('journal.electronic_sheets.dob_missing')"
                            :stacked="false"
                        />
                        <!-- FORM CONTENT-->
                        <div v-for="(tag,index) in jsonParsed" :key="index">
                            <div v-if="!isHidden(tag)">
                                <!-- HEADING -->
                                <div v-if="'opening' in tag">
                                    <div v-if="'many' in tag">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h4>{{ getHeading(tag) }}</h4>
                                            <div>
                                                <v-btn :disabled="tag.count <= 1" @click="removeItem(tag)"> - </v-btn>
                                                <v-btn @click="addItem(tag)"> + </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="d-flex justify-content-between">
                                            <h4>{{ getHeading(tag) }}</h4>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <!-- CLOSING -->
                                <hr v-if="'closing' in tag" class="heading-hr mt-3">
                                <div v-if="'datatype' in tag">
                                    <!-- STRING -->
                                    <div v-if="tag.datatype === 'string'" class="my-4">
                                        <v-textarea 
                                            :disabled="'disabled' in tag"
                                            rows="1"
                                            auto-grow
                                            variant="outlined" 
                                            density="compact"
                                            hide-details="auto"
                                            :label="getLabel(tag)"
                                            v-model="formValues.find(value => value.lineid === tag.lineid).value"
                                        />
                                    </div>
                                    <!-- BOOL -->
                                    <div v-if="tag.datatype === 'bool'" class="d-flex align-items-center bool-height" >
                                        <div class="mr-1 my-auto text-grey font-weight-bold">
                                            {{ getLabel(tag) }}:
                                        </div>
                                        <div class="d-flex my-auto">
                                            <v-checkbox 
                                                :disabled="'disabled' in tag"
                                                class="mt-n2 mr-1"
                                                v-model="formValues.find(value => value.lineid === tag.lineid).boolIsTrue" 
                                                @change="updateBoolFormvalue(tag.lineid, true)">
                                                <template v-slot:label><div class="d-flex mt-2 flex-nowrap">já</div></template>
                                            </v-checkbox>
                                            <v-checkbox 
                                                :disabled="'disabled' in tag"
                                                class="mt-n2"
                                                v-model="formValues.find(x => x.lineid === tag.lineid).boolIsFalse" 
                                                @change="updateBoolFormvalue(tag.lineid, false)">
                                                <template v-slot:label><div class="d-flex mt-2 flex-nowrap">nei</div></template>
                                            </v-checkbox>
                                        </div>
                                    </div>
                                    <!-- DATE / DATETIME -->
                                    <div v-if="tag.datatype === 'date' || tag.datatype === 'datetime'" class="my-4">
                                        <DatePicker :disabled="'disabled' in tag" :type="tag.datatype" :title="getLabel(tag)" :lineId="tag.lineid" @datechange="updateDateTimeFormValue" />
                                    </div>
                                    <!-- INTEGER -->
                                    <div v-if="tag.datatype === 'integer'" class="my-4">
                                        <v-text-field
                                            v-model="formValues.find(value => value.lineid === tag.lineid).value"
                                            :disabled="'disabled' in tag"
                                            :rules="intrules"
                                            :label="getLabel(tag)"
                                            hide-details="auto"
                                            variant="outlined" 
                                            density="compact"
                                        />
                                    </div>
                                    <!-- FLOAT -->
                                    <div v-if="tag.datatype === 'float'" class="my-4">
                                        <v-text-field
                                            v-model="formValues.find(value => value.lineid === tag.lineid).value"
                                            :disabled="'disabled' in tag"
                                            :rules="floatrules"
                                            :label="getLabel(tag)"
                                            hide-details="auto"
                                            variant="outlined" 
                                            density="compact"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- RECIPIENT --> 
                        <AddressForm :addresses="addresses" @changeAddress="updateSelectedAddress" />
                        <div v-if="noAddress" class="d-flex justify-content-center text-danger">
                            <small>{{ $t('journal.electronic_sheets.recipient_missing') }}</small>
                        </div>
                    </v-container>
                </v-form>
            </v-card-text>
            <!-- <h4 v-if="this.debug && !preview">ElectronicSheetId:{{ esId }}</h4> -->
            <!-- <h4 v-if="this.debug && !preview">Kind:{{ kindOfSheet ? kindOfSheet : '' }}</h4> -->
            <!-- <div v-if="this.debug && !preview"> -->
            <!--     <p>Athugið: Það þarf að slökkva á debug mode til að senda</p> -->
            <!--     <v-btn @click="turnOffDebug()" variant="outlined" class="mx-auto mt-4 no-upper" color="primary">Slökkva á debug</v-btn> -->
            <!-- </div> -->
            <!-- WRAPPER (for displaying debug into & pdf test) -->
            <!-- <Wrapper  -->
            <!--     v-if="this.kindOfSheet && !preview" -->
            <!--     :debug="this.debug" -->
            <!--     :preview="preview" -->
            <!--     :kind="kindOfSheet" -->
            <!--     :address="address" -->
            <!--     :form="jsonParsed" -->
            <!--     :data="formValues" -->
            <!--     :storeDisabled="!isFormValid" -->
            <!--     @store="storeAndSend" -->
            <!--     :fromHeader="false" -->
            <!-- /> -->
        </v-card>
    </v-container>
</template>

<script>

import ElectronicSheetsAPI from '@src/api/electronicSheets';
import Wrapper from './wrapper.vue';
import DatePicker from './helpers/esDatePicker.vue';
import AddressForm from './helpers/addressForm.vue';

import { useSnackbarStore } from '@stores/snackbarStore';

export default {
    components: { DatePicker, AddressForm, Wrapper }, 
    created() {
        let access = this.$store.getters['currentUserAccess'];
        if('admin' in access && access.admin) {
            this.debug = true;
        }
    },
    watch: {
        show() {
            if(this.show) {
                this.$refs.scrollTop.scrollIntoView(true);
            }
        },
        address() {
            if('eid' in this.address) {
                this.noAddress = false;
            }
        },
        // empty form
        esId() {
            if(this.esId <= 0) {
                // this.show = false;
                return;
            }
            ElectronicSheetsAPI.getSingleSheet(this.esId)
                .then(res => {
                    const data = res.data.data[0];
                    this.jsonParsed = JSON.parse(data.json);
                    this.init();
                    this.loaded = true;
                })
                .catch(err => console.log('error in init: 1', err));
        }
    },
    props: {
        // is true if coming from es-parse, false in journal
        preview: {
            type: Boolean,
            required: false,
            default: false
        },
        show: {
            type: Boolean,
            required: false,
            default: false
        },
        esId: {
            type: String,
            required: false,
            default: -1
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            title: '',
            address: {},
            noAddress: false, // no error is shown to begin with
            debug: false,
            loaded: false,
            jsonParsed: [],
            formValues: [],
            kindOfSheet: '',
            isFormValid: true,
            addresses: this.getAddresses(),
            intrules: [value => (this.intCheck(value)) || 'ógild heiltala'],
            floatrules: [value => (this.floatCheck(value)) || 'ógild kommutala'],
            dobMissing: false,
            snackbarStore: useSnackbarStore(),
            // used for scroll functionality
            formHeight: 0,
            innerFormHeight: 0
        }
    },
    methods: {
        handleResize() {
            const margin = 70;
            this.formHeight = window.innerHeight - 2 * margin;
            this.innerFormHeight = this.formHeight - margin;
        },
        setDobMissing() {
            this.dobMissing = true;
        },
        isHidden(tag) {
            return 'hidden' in tag && tag.hidden === true;
        },
        turnOffDebug() {
            this.debug = false;
        },
        updateSelectedAddress(address) {
            this.address = address
        },
        setTitle(tag) {
            try {
                const entry = `journal.electronic_sheets.${tag}`;
                const title = this.$t(entry);
                this.title = title;
            }
            catch {}
        },
        getLabel(tag) {
            if('translation' in tag) {
                return tag.translation;
            }
            return tag.tag;
        },
        // allows empty strings and integers through
        intCheck(value) {
            if(value.length === 0) {
                this.isFormValid = true;
                return true;
            }
            const numbers = "0123456789".split('');
            const str = value.toString().split('');
            for(let letter of str) {
                if(numbers.indexOf(letter) < 0) {
                    this.isFormValid = false;
                    return false;
                }
            }
            this.isFormValid = true;
            return true;
        },
        floatCheck(value) {
            if(value.length === 0) {
                this.isFormValid = true;
                return true;
            }
            const index = value.indexOf(',');
            if(index > 0) {
                const parts = value.split(',');
                // two commas
                if(parts.length > 2) {
                    this.isFormValid = false;
                    return false;
                }
                let firstPart = parts[0], secondPart = parts[1];
                if(firstPart.length === 0 || secondPart.length === 0) {
                    this.isFormValid = false;
                    return false;
                }
                firstPart = this.intCheck(firstPart);
                secondPart = this.intCheck(secondPart);
                return firstPart && secondPart;
            } else {
                return this.intCheck(value);
            }
        },
        init() {
            let result = [];
            for(let item of this.jsonParsed) {
                if(!('datatype' in item)) {
                    continue;
                }
                let value = '';
                const autofill = item.autofill != 'off';
                if(autofill) {
                    value = this.$store.getters[item.autofill];
                }                 
                let entry = {};
                entry.lineid = item.lineid;
                entry.value = value;
                result.push(entry);
            }
            this.formValues = result;
            this.kindOfSheet = this.jsonParsed[0].kind;
            this.setTitle(this.jsonParsed[0].tag);
        },
        addItem(tag) {
            const lid = tag.lineid;
            let si = -1, ei = -1, i = 0, j = 0;
            // jsonParsed
            let before = [], between = [], after = [];
            // formValues
            let fbefore = [], fbetween = [], fafter = [];

            for(let item of this.jsonParsed) {
                if(item.lineid === lid && 'opening' in item) {
                    si = i;
                }
                if(si >= 0 && 'closing' in item) {
                    ei = i;
                    break;
                }
                i += 1;
            }

            i = 0;

            for(let item of this.jsonParsed) {
                // can be in two arrays so...
                let obj = JSON.parse(JSON.stringify(item));
                let obj2 = JSON.parse(JSON.stringify(item));
                let val = ''
                if('datatype' in item) {
                    val = this.formValues.find(v => v.lineid === obj.lineid);
                    val = JSON.parse(JSON.stringify(val));
                }

                if(i <= ei) {
                    if(i === si) {
                        delete obj['many'];
                        delete obj['count'];
                    }
                    before.push(obj);
                    if('datatype' in item) {
                        fbefore.push(val);
                    }
                }
                if(i >= si && i < ei) {
                    if('opening' in item && 'many' in item) {
                        obj2['count'] = item['count'] + 1;
                    }
                    between.push(obj2)
                    if('datatype' in item) {
                        fbetween.push({ 'lineid': -1, 'value': ''});
                    }
                }
                if(i >= ei) {
                    after.push(obj);
                    if('datatype' in item) {
                        fafter.push(val);
                    }
                }
                i += 1;
            }

            this.jsonParsed = [...before, ...between, ...after];
            this.formValues = [...fbefore,...fbetween,...fafter];

            i = 1;
            j = 0;
            for(let item of this.jsonParsed) {
                item.lineid = i;
                if('datatype' in item) {
                    this.formValues[j].lineid = i;
                    j += 1;
                }
                i += 1;
            }
        },
        debug(ii) {
            let i = 0;
            let msg = ' ';
            for(let item of this.jsonParsed) {
                msg = ' ';
                if(ii > 0 && ii == i) {
                    msg = '<------';
                } 
                if('opening' in item) {
                    console.log(i,item.tag, 'O'+msg);
                } 
                else if('closing' in item) {
                    console.log(i,item.tag, 'C'+msg);
                } else {
                    console.log(i,item.tag,' '+msg);
                }
                i += 1;
            }
        },
        removeItem(tag) {
            if(tag.count < 2) {
                return;
            }

            const cc = tag.count, lid = tag.lineid;
            let si = -1, ei = -1, i = 0, j = 0;

            for(let item of this.jsonParsed) {
                if(item.lineid === lid && 'opening' in item) {
                    si = i;
                }
                if(si >= 0 && 'closing' in item) {
                    ei = i;
                    break;
                }
                i += 1;
            }

            const len = ei - si + 1;

            // update tag (opening, before what is to be removed)
            i = si - 1;
            while(i >= 0) {
                let item = this.jsonParsed[i];
                if('opening' in item) {
                    item['many'] = true;
                    item['count'] = cc - 1;
                    break;
                }
                i -= 1;
            }

            let removed = this.jsonParsed.splice(si, len);
            let remindexes = removed.map(v => v.lineid);
            let newFormValues = [];
            for(let item of this.formValues) {
                if(remindexes.indexOf(item.lineid) >= 0) {
                    continue;
                }
                newFormValues.push(item);
            }

            i = 1;
            j = 0;

            for(let item of this.jsonParsed) {
                item.lineid = i;
                if('datatype' in item) {
                    newFormValues[j].lineid = i;
                    j += 1;
                }
                i += 1;
            }
            this.formValues = newFormValues;
        },
        // boolIsTrue and boolIsFalse are present so user can leave value empty (neither true nor false)
        // (also for toggle or radio functionality)
        // if a neither is checked then the value key is removed
        updateBoolFormvalue(lineId, trueChecked) {
            const item = this.formValues.find(item => item.lineid === lineId);
            let trueKeyExists = 'boolIsTrue' in item, falseKeyExists = 'boolIsFalse' in item;
            if(trueChecked) {
                item.value = true;
                // uncheck false if true was checked
                if(trueKeyExists && item.boolIsTrue) {
                    item ? item.boolIsFalse = false : '';
                }
            } else {
                item.value = false;
                // uncheck true if false was checked
                if(falseKeyExists && item.boolIsFalse) {
                    item ? item.boolIsTrue = false : '';
                }
            }

            // if user does not want to answer (key is deleted)
            let trueIsNotSet = false, falseIsNotSet = false;
            trueIsNotSet = !trueKeyExists || !item.boolIsTrue;
            falseIsNotSet = !falseKeyExists || !item.boolIsFalse;
            const neither = trueIsNotSet && falseIsNotSet;
            neither ? delete item['value'] : '';
        },
        updateDateTimeFormValue(lineId, date, time) {
            const jp = this.jsonParsed.find(item => item.lineid === lineId);
            const type = jp.datatype;
            if(!type in ['date', 'datetime']) {
                return;
            }
            const item = this.formValues.find(item => item.lineid === lineId);
            if(time) {
                const year = date.getUTCFullYear(); 
                const month = date.getUTCMonth(); 
                const day = date.getUTCDate(); 

                const parts = time.split(':');
                const h = parseInt(parts[0]);
                const m = parseInt(parts[1]);
                date = new Date(year, month, day, h, m, 0);
            }

            item.value = date.toISOString();
        },
        getHeading(tag) {
            if('heading' in tag) {
                return tag.heading
            }
            return tag.tag
        },
        storeAndSend(dto, formPart, uuid, testing) {
            // console.log('%c '+ dto, 'background: #000000; color: #ffffff');
            this.noAddress = false;
            if(this.esId <= 0 || uuid.length === 0) {
                console.log('error in id or uuid')
                return;
            }

            const addressSelected = 'eid' in this.address;
            if(!addressSelected) {
                this.snackbarStore.activate(this.$t('journal.electronic_sheets.recipient_missing'), 'error', 5000);
                this.noAddress = true;
                return;
            }

            // merging the objects
            this.jsonParsed[0]['title'] = this.title;
            const jsonParsed = JSON.parse(JSON.stringify(this.jsonParsed));
            const formValues = JSON.parse(JSON.stringify(this.formValues));
            const copy1 = structuredClone(jsonParsed);
            const copy2 = structuredClone(formValues);

            const merged = copy1.map(item1 => {
                const item2 = copy2.find(item2 => item2.lineid === item1.lineid);
                return { ...item1, ...item2 };
            });

            const currentPatientId = this.$store.getters.currentPatientID;
            const currentUnitId = this.$store.getters.currentUnitID;

            if(!currentPatientId) {
                this.snackbarStore.activate('Skjólstæðingur er ekki skráður inn', 'error', 2000);
                return;
            }
            if(!currentUnitId) {
                this.snackbarStore.activate('Þú virðist ekki vera skráður á stofnun', 'error', 2000);
                return;
            }

            if(this.address.eid.indexOf(';') < 0) {
                this.snackbarStore.activate('Það vantar viðtakanda', 'error', 2000);
                return;
            }

            const node = this.address.eid.split(';')[0];

            const data = {
                'electronic_sheet_id':this.esId,
                'json':merged,
                'patient_id':currentPatientId,
                'journal_type_id':1,
                'unit_id':currentUnitId,
                'dto':dto,
                'form_part':formPart,
                'node':node,
                'address':this.address.eid,
                'uuid':uuid
            };

            if(testing) {
                ElectronicSheetsAPI.storeToLog({'form':data})
                    .then(res => {
                        const status = res.data.status;
                        if(status === 'saved') {
                            this.snackbarStore.activate('Gögn hafa verið vistuð í MsgLog fyrir PDF testing', 'success', 4000);
                        } else {
                            const reason = res.data.reason;
                            console.log(reason)
                            this.snackbarStore.activate('Villa út af: '+ reason, 'error', 2000);
                            throw new Error;
                        }
                    })
                    .catch(() => {
                        this.snackbarStore.activate('Ekki gekk að vista í MsgLog, sjá console', 'error', 2000);
                    });
            } else {
                ElectronicSheetsAPI.storeData({'form':data})
                    .then(res => {
                        const status = res.data.status;
                        if(status === 'saved') {
                            this.snackbarStore.activate('Gögn hafa verið vistuð', 'success', 2000);
                            this.close();
                        } else {
                            const reason = res.data.reason;
                            console.log(reason)
                            throw new Error;
                        }
                    })
                    .catch(err => {
                        this.snackbarStore.activate('Ekki gekk að senda skjal', 'error', 2000);
                    });
            }
        },
        close() {
            this.loaded = false;
            this.jsonParsed = [];
            this.formValues = [];
            this.kindOfSheet = '';
            this.address = {};
            this.noAddress = false;
            this.$emit('closeSheet');
        },
        getAddresses() {
            ElectronicSheetsAPI.getAddresses('case')
                .then(res => {
                    let result = [], seen = [];
                    const addresses = res.data.data;
                    const temp = _.orderBy(addresses, 'name');
                    // removing duplicates
                    for(let object of temp) {
                        const name = object.name;
                        if(seen.includes(name)) {
                            continue;
                        }
                        seen.push(name);
                        result.push(object);
                    }
                    this.addresses = result;
                })
                .catch(err => {
                    console.log(err);
                    this.activateSnackBar('Ekki gekk að sækja móttakendur!', 'error');
                });
        }
    }
}
</script>
<style scoped>
.heading-text {
flex: 0 0 auto;
}
.heading-hr {
flex: 1;
}
.no-upper {
text-transform: unset !important;
}
.bool-height {
height: 40px;
}
</style>
