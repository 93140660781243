// import { createApp } from 'vue';
// import emitter from 'tiny-emitter/instance';

// const app = createApp({});

// // Register the event emitter globally
// app.config.globalProperties.$bus = emitter;

// // Method to set the event handler
// app.config.globalProperties.setEventHandler = function(event, cb) {
//     this[event + 'Handler'] = cb;
// };

// // Method to listen to an event
// app.config.globalProperties.listenToEvent = function(event, cb) {
//     const listener = () => {
//         this[event + 'Handler'] = cb;
//     };
//     listener();
//     return listener;
// };

// export default app;

// event-bus.js
import emitter from 'tiny-emitter/instance';

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};