<template>
    <nav class="navbar fixed-top navbar-expand-md navbar-light bg-light shadow-sm">
        <div class="container">
            <router-link 
                v-tooltip.bottom="$t('navbar.home')" 
                class="egatt-navbar-logo navbar-brand" 
                :to="{ name: 'home' }"
            >
                <logo-egatt width="30" height="30" />
            </router-link>
            <button 
                class="navbar-toggler bg-primary text-white" 
                type="button" 
                data-toggle="collapse"
                @click.prevent="toggleSidebar" 
            >
                <i class="fas fa-bars" />
            </button>
            <div id="egatt-navbar-menu" class="collapse navbar-collapse">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <router-link 
                            v-tooltip.bottom="$t('navbar.new_invoice')" 
                            :to="{name: 'invoice-create'}" 
                            class="nav-link"
                        >
                            <i class="fal fa-dollar-sign fa-fw" />
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link 
                            v-if="currentUserAccess['prescriptions']" 
                            v-tooltip.bottom="$t('navbar.new_digital_prescription')" 
                            :to="{name: 'presc-create'}" 
                            class="nav-link"
                        >
                            <i class="fal fa-prescription fa-fw" />
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link 
                            v-if="currentUserAccess['drugs']" 
                            v-tooltip.bottom="$t('navbar.national_drug_database')" 
                            :to="{name: 'presc-registry'}" 
                            class="nav-link"
                        >
                            <i class="fal fa-pills fa-fw" />
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link 
                            v-if="currentUserAccess['records']" 
                            v-tooltip.bottom="$t('navbar.national_health_records')" 
                            :to="{name: 'patient-index'}" 
                            class="nav-link"
                        >
                            <i class="fal fa-exchange fa-fw" />
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link 
                            v-if="currentUserAccess['admin']" 
                            v-tooltip.bottom="$t('navbar.admin_interface')" 
                            :to="{name: 'admin'}" 
                            class="nav-link"
                        >
                            <i class="fal fa-cogs fa-fw" />
                        </router-link>
                    </li>
                </ul>

                <li class="nav-item" style="list-style-type: none;">
                    <button @click="toggleSound"
                            v-if="muteSound"
                            v-tooltip.bottom="$t('navbar.turn_off_notif')"
                            class="nav-link bell-btn rounded"
                            style="cursor: pointer;"
                    >
                        <i class="fal fa-bell"></i>
                    </button>
                    <button @click="toggleSound"
                            v-else
                            v-tooltip.bottom="$t('navbar.turn_on_notif')"
                            class="nav-link bell-btn rounded"
                            style="cursor: pointer;"
                    >
                        <i class="fal fa-bell-slash"></i>
                    </button>
                </li>
                
                <br>
                <navbar-patient :inline="true" />
                <button 
                    v-tooltip="$t('navbar.menu')"
                    class="btn btn-primary my-2 my-sm-0"
                    @click.prevent="toggleSidebar" 
                >
                    <i class="fas fa-bars" />
                </button>
            </div>
            <transition name="slide-left">
                <sidebar v-if="showSidebar" @close="showSidebar = false" @loggedOut="onLogout"/>
            </transition>
        </div>
    </nav>
</template>

<script setup>
import { ref, onMounted, computed, defineEmits} from 'vue';
import LogoEgatt from '../logoEgatt';
import NavbarPatient from '../navbarPatient';
import Sidebar from '../sidebar';
import localService from '@src/api/local';
import { useStore } from 'vuex';

const store = useStore();

const showSidebar = ref(false);
const muteSound = ref(false);

const emit = defineEmits(['loggedOut']);

onMounted(() => {
    shouldMuteSound();
});

const currentUserAccess = computed(() => store.getters.currentUserAccess);

const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value;
};

const toggleSound = () => {
    muteSound.value = !muteSound.value;
    localService.toggleBoolSetting('mute_alert', muteSound.value)
};

const shouldMuteSound = () => {
    localService.getSettingsValueBySlug('mute_alert').then(res => {
        muteSound.value = Boolean(res);
    });
};

const onLogout = () => {
    emit('loggedOut');
};
</script>

<style scoped>
  /* Define the slide transition classes */
  .slide-left-enter-active, .slide-left-leave-active {
    transition: transform 0.3s ease;
  }
  .slide-left-enter-from, .slide-left-leave-to /* .slide-leave-active in <2.1.8 */ {
    transform: translateX(100%);
  }
</style>
