<template>
    <div class="form-group">
        <label>{{ $t('invoice_create.service_recipient') }}</label>
        <div class="input-group">
            <input 
                v-model="searchCodenumber" 
                :disabled="disabled"
                :class="inputClass" 
                :placeholder="$t('invoice_create.ssn')" 
                type="text" 
                :name="name"
                @keydown.enter="getPatient" 
                @click.stop="" 
            >
            <div class="input-group-append">
                <button 
                    :disabled="disabled" 
                    :class="buttonClass" 
                    type="button"
                    @click.stop="getPatient" 
                >
                    <i :class="buttonIcon" />
                </button>
            </div>
        </div>
        <div v-if="error" class="alert alert-danger mt-2">
            <h4 class="alert-heading">{{ t('rates.service_recipient_not_found') }}</h4>
            <p>{{ error }}</p>
        </div>
    </div>
</template>
<script>
import PatientApi from '@src/api/patient';
import { FIND_PATIENT_BY_CODENUMBER } from '@src/store/actionTypes';
export default {
    components: {},
    props: {
        dispatch: {
            type: Boolean,
            required: false,
            default: true
        },
        name: {
            type: String,
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: undefined
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        inputClass: {
            type: String,
            required: false,
            default: 'form-control'
        },
        buttonIcon: {
            type: String,
            required: false,
            default: 'fal fa-search'
        },
        buttonClass: {
            type: String,
            required: false,
            default: 'btn btn-outline-secondary'
        },
        placeholder: {
            type: String,
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            searchCodenumber: '',
            error: null
        }
    },
    methods: {
        t(key) {
            return this.$t(key);
        },
        getPatient() {
            this.error = null;
            if (this.dispatch) {
                this.$store.dispatch(FIND_PATIENT_BY_CODENUMBER, this.searchCodenumber).then(res => {
                    this.$emit('update');
                }).catch(err => {
                    this.error = this.$t('invoice_create.no_patients_found_with_this_SSN');
                });
            } else {
                PatientApi.getPatientByCodenumber(this.searchCodenumber).then(({ data }) => {
                    this.$emit('update', data);
                }).catch(err => {
                    this.error = this.$t('invoice_create.no_patient_found_with_this_id');
                    this.$emit("error", this.error);
                });
            }
        }
    }
}
</script>
<style></style>