<template>
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4 mt-2">
          <div class="row">
            <PatientCard wrapperClass="col-lg-6 align-items-md-stretch p-1"/>
            <div class="col-lg-6 align-items-stretch">
              <div class="row">
                <div class="col-sm-6 p-1">
                  <router-link 
                    class="btn btn-primary btn-block p-4" 
                    :to="{name: 'invoice-create'}" 
                  >
                    <i class="fal fa-file-invoice-dollar dashboard-icon" /> {{ $t('patients_dashboard.new_invoice') }}
                  </router-link>
                </div>
  
                <div class="col-sm-6 p-1">
                  <router-link 
                    v-if="currentUserAccess['prescriptions']" 
                    class="btn btn-primary btn-block p-4" 
                    :to="{name: 'presc-create'}"
                  >
                    <i class="fal fa-file-prescription dashboard-icon" /> {{ $t('patients_dashboard.new_prescription') }}
                  </router-link>
                  <button v-else class="btn btn-primary btn-block p-4 disabled">
                    <i class="fal fa-lock-alt dashboard-icon" /> {{ $t('patients_dashboard.new_prescription') }}
                  </button>
                </div>
  
                <div class="col-sm-6 p-1">
                  <router-link 
                    v-if="currentUserAccess['drugs']" 
                    :to="{name: 'presc-registry'}" 
                    class="btn btn-primary btn-block p-4"
                  >
                    <i class="fal fa-pills dashboard-icon" /> {{ $t('patients_dashboard.drug_database') }}
                  </router-link>
                  <button 
                    v-else 
                    class="btn btn-primary btn-block p-4 disabled"
                  >
                    <i class="fal fa-lock-alt dashboard-icon" /> {{ $t('patients_dashboard.drug_database') }}
                  </button>
                </div>
  
                <div class="col-sm-6 p-1">
                  <router-link 
                    v-if="currentUserAccess['records']" 
                    class="btn btn-primary btn-block p-4" 
                    :to="{name: 'patient-index'}"
                  >
                    <i class="fal fa-notes-medical samtengd-icons" />
                    <i class="far fa-exchange icon-lift" />
                    <i class="fas fa-notes-medical samtengd-icons" />
                    <br class="samtengd-break">
                    {{ $t('patients_dashboard.national_health_register') }}
                  </router-link>
                  <button v-else class="btn btn-primary btn-block p-4 disabled">
                    <i class="fal fa-lock-alt dashboard-icon" /> {{ $t('patients_dashboard.national_health_register') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-6 align-items-md-stretch p-1">
          <div class="mb-auto h-100 bg-info text-white p-4 rounded">
            <i class="fal fa-user-md fa-5x" />
            <i 
               id="dashboard-doctor-cog" 
                v-tooltip="$t('doctor_dashboard.change_email')" 
                class="fas fa-cog fa-2x float-right" 
                @click.stop="showChangeEmail = true"
            />

            <br>
            <h2 class="mt-3">{{ $t('doctor_dashboard.doctor') }}</h2>
            <p>
              {{ $t('doctor_dashboard.name') }} : {{ currentUserName }}<br>
              {{ $t('doctor_dashboard.institution') }} : {{ currentUnitInstitute }} <br>
              {{ $t('doctor_dashboard.unit') }}: {{ currentUnitDepartment }}
            </p>
          </div>
        </div>
  
        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-6 p-1">
              <div class="btn btn-info btn-block p-4 disabled">
                <span class="arrivals-digit">{{ seenPatients < 0 ? 0 : seenPatients }}</span>
                <span v-if="seenPatients == 1">{{ $t('doctor_dashboard.arrival') }}</span>
                <span v-else>{{ $t('doctor_dashboard.arrivals') }}</span>
              </div>
            </div>
            <div class="col-sm-6 p-1">
              <router-link 
                v-if="currentUserAccess['prescriptions']" 
                class="btn btn-info btn-block p-4" 
                :to="{name: 'presc-index'}"
              >
                <span class="arrivals-digit">{{ prescriptions }}</span>
                {{ $t('doctor_dashboard.prescriptions') }}
              </router-link>
              <button 
                v-else 
                class="btn btn-info btn-block p-4 disabled"
              >
                <i class="fal fa-lock-alt dashboard-icon" aria-hidden="true" /> {{ $t('doctor_dashboard.prescriptions') }}
              </button>
            </div>
          </div>
          <div class="row">
            <div 
              class="col-sm-6 p-1"
              @mouseover="showBalance = true" 
              @mouseout="showBalance = false" 
            >
              <router-link 
                class="btn btn-info btn-block p-4" 
                :to="{name: 'cr-index'}"
              >
                <span v-if="showBalance" class="balance-digit">{{ $filters.currency($filters.scale(income)) }}</span>
                <span v-else><i class="fal fa-envelope-open-dollar dashboard-icon" /></span>
                {{ $t('doctor_dashboard.settlement') }}
              </router-link>
            </div>
            <div class="col-sm-6 p-1">
              <router-link class="btn btn-info btn-block p-4" :to="{name: 'invoice-index'}">
                <i class="fal fa-box-usd dashboard-icon" /> {{ $t('doctor_dashboard.invoice_archive') }}							
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <EmailChange 
        v-if="showChangeEmail"
        :email="currentUserEmail" 
        @close="showChangeEmail = false" 
      />
    </div>
  </template>


<script>
import { computed, ref } from 'vue';
import EmailChange from '@components/emailChange';
import PatientCard from '@components/patient/patientCard/';
import { useStore } from 'vuex';
// import { GET_DOCTORS_RECENT_PRESCRIPTIONS, GET_DOCTORS_INVOICES_TODAY } from "../../store/actionTypes";

export default {
  components: { 
    EmailChange,
    PatientCard
  },
  setup() {
    const store = useStore();

    const invoices = ref([]);
    const showBalance = ref(false);
    const showChangeEmail = ref(false);
    const showSelectPatient = ref(false);

    const currentUserName = computed(() => store.getters.currentUserName);
    const currentUserEmail = computed(() => store.getters.currentUserEmail);
    const currentUserAccess = computed(() => store.getters.currentUserAccess);
    const currentPatientName = computed(() => store.getters.currentPatientName);
    const currentPatientCodenumber = computed(() => store.getters.currentPatientCodenumber);
    const currentPatientAddress = computed(() => store.getters.currentPatientAddress);
    const currentPatientAddressZip = computed(() => store.getters.currentPatientAddressZip);
    const currentPatientAddressCity = computed(() => store.getters.currentPatientAddressCity);
    const currentUnitDepartment = computed(() => store.getters.currentUnitDepartment);
    const currentUnitInstitute = computed(() => store.getters.currentUnitInstitute);
    const doctorInvoices = computed(() => store.getters.doctorInvoices);
    const doctorPrescriptions = computed(() => store.getters.doctorPrescriptions);
    const client = computed(() => store.getters.client);

    const seenPatients = computed(() => {
      return doctorInvoices.value.reduce((sum, item) => {
        if ((new Date()).isSameDate(item.appointment_date.replace(' ', 'T'))) {
          return sum + (item.credit_invoice == '0' ? 1 : -1);
        }
        return sum;
      }, 0);
    });

    const income = computed(() => {
      return doctorInvoices.value.reduce((sum, item) => sum += Number(item.total), 0);
    });

    const prescriptions = computed(() => {
      return doctorPrescriptions.value.filter(p => (new Date()).isSameDate(p.date_created.replace(' ', 'T'))).length;
    });

    const onPatientInvoiceClick = () => {
      this.$router.push({name: 'invoice-patient'});
    };

    const toggleShowSelectPatient = () => {
      showSelectPatient.value = !showSelectPatient.value;
    };

    const onPatientChange = () => {
      showSelectPatient.value = false;
    };

    const openModal = () => {
      showChangeEmail.value = true;
    };

    return {
      showChangeEmail,
      showBalance,
      showSelectPatient,
      invoices,
      currentUserName,
      currentUserEmail,
      currentUserAccess,
      currentPatientName,
      currentPatientCodenumber,
      currentPatientAddress,
      currentPatientAddressZip,
      currentPatientAddressCity,
      currentUnitDepartment,
      currentUnitInstitute,
      doctorInvoices,
      doctorPrescriptions,
      client,
      seenPatients,
      income,
      prescriptions,
      onPatientInvoiceClick,
      toggleShowSelectPatient,
      onPatientChange,
      openModal,
      EmailChange,
    };
  }
};

</script>