<template>
	<v-card>
		<v-toolbar color="transparent">
			<div @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave()">
				<v-avatar :color="getHoverColor(index)" density="compact">
					<v-icon v-if="hoveredItemIndex === index" size="small"
						@click.stop="editDiagnose(journalData)">mdi-pencil</v-icon>
					<v-icon v-else size="small" :class="getAvatarIconClass(index)"></v-icon>
				</v-avatar>
			</div>
			<v-toolbar-title>
				<strong>{{ journalData.data.code }}</strong>
				<v-chip v-if="journalData.data.dia_list_name && journalData.data.dia_list_name != ''" class="ml-3"
					size="small">
					{{ journalData.data.dia_list_name }}
				</v-chip>
				<v-chip v-if="isDraft()" prepend-icon="mdi-progress-alert" size="small"
					class="text-0 user-select-none ml-3" color="warning">
					{{ $t('journal.view.draft') }}
				</v-chip>
			</v-toolbar-title>
			<v-chip prepend-icon="mdi-doctor" class="text-0 user-select-none" size="small">
				{{ journalData.user }}
			</v-chip>
		</v-toolbar>
		<p class="journal-indent">{{ journalData.data.name }}</p>
	</v-card>
</template>

<script>
import DiagnoseEditor from '../../journalEditor/diagnoseEditor/diagnoseEditor.vue';

export default {
	components: {
		DiagnoseEditor
	},
	props: {
		journalData: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			hoveredItemIndex: null,
		};
	},
	methods: {
		isDraft() {
			const draft = this.journalData['data']['metadata']['draft'];
			return draft === '1';
		},
		handleMouseEnter(index) {
			this.hoveredItemIndex = index;
		},
		handleMouseLeave() {
			this.hoveredItemIndex = null;
		},
		getHoverColor(index) {
			// index is always undefined!
			return this.hoveredItemIndex === index ? 'grey' : 'teal-lighten-2';
		},
		getAvatarIconClass(index) {
			return this.hoveredItemIndex !== index ? 'fat fa-person-dots-from-line' : '';
		},
		editDiagnose(item) {
			const id = item.data.data_row_id;
			this.$emit('edit-item', { id: id, type: '2', version: item.data.version });
		}
	}
};
</script>
<style scoped>
.journal-indent {
	margin-left: 52px;
}
</style>
