import userSettings from '@src/api/userSettings';
import { defineStore } from 'pinia';

export const useUserSettingStore = defineStore('userSetting', {
  state: () => ({
    allSettings: [],
    diagnosis_editor_default_dialist_id: ""
  }),
  getters: {
    getAllUserSettings() {
      return this.allSettings
    },
  },
  actions: {
    initialize(settings) {
      this.allSettings = settings;
    },
    getDefaultUnit() {
      return this.allSettings.find(obj => obj.slug === 'login_default_unit').value;
    },
    getDefaultAutoLetterID() {
      const id = this.allSettings.find(obj => obj.slug === 'autoletter_default_id').value;
      return id ? parseInt(id) : -1;
    },
    getJournalSettingsCompactSettings() {
      const days = this.allSettings.find(obj => obj.slug === 'journal_days_compact_mode');
      const notes = this.allSettings.find(obj => obj.slug === 'journal_notes_compact_mode');
      if (!days && !notes) {
        // do run UserSettingSeeder
        return { 'days': false, 'notes': false };
      }
      return { 'days': days.value === '1', 'notes': notes.value === '1' };
    },
    updateValue(slug, value) {
      for (let setting of this.allSettings) {
        if (setting.slug === slug) {
          setting.value = value;
        }
      }
    },
    getSettingBysSlug(slug) {
      const requiredSlug = this.allSettings.find((setting) => setting.slug === slug);
      return requiredSlug.value;
    },
    async setSettingBySlug(slug, slugValue) {
      this.updateValue(slug, slugValue);  // This will update the value of setting in store 

      const data = { value: slugValue.toString() };

      try {
        const response = await userSettings.update(slug, data);  // This will update the value of setting in API 
        return response;
      } catch (error) {
        throw error;
      }
    }
  }
});
