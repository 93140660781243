<template>
    <div class="form-group">
        <label-basic v-if="label !== undefined" :required="required">
            {{ label }}
        </label-basic>
        <textarea 
            class="form-control" 
            :class="{'is-invalid': error}"
            ref="text"
            :rows="rows" 
            :value="modelValue"
            @input="onInput"
            :tabindex="String(tabindex)"
            style="min-height: 2.5rem;"
      ></textarea>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import LabelBasic from '../labelBasic';

export default defineComponent({
    components: {
        LabelBasic
    },
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 3
        },
        error: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: undefined,
        },
        tabindex: { 
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const text = ref(null);

        const onInput = (event) => {
            emit('update:modelValue', event.target.value);
        };

        return {
            text,
            onInput
        };
    }
});
</script>
