<template>
  <v-container class="p-0">
    <term-name v-model="termName" />
    <v-number-input 
      v-model="termValue" 
      :suffix="unit" 
      control-variant="default" 
      density="compact" 
      variant="outlined"
      hide-details
      @update:modelValue="onTermValueChange" 
    />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import { VNumberInput } from 'vuetify/labs/VNumberInput';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Numeric',
  components: {
    TermName,
    VNumberInput
  },
  props: {
    unit: {
      type: String,
      required: true
    },
    keywordData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      termName: 'Number input type',
      termValue: null
    };
  },
  watch: {
    keywordData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.termName = newVal.term_name || this.termName;
          this.termValue = newVal.term_value || null;
        }
      }
    },
    termName(newVal) {
      this.$emit('update:keywordData', { ...this.keywordData, term_name: newVal });
    }
  },
  methods: {
    onTermValueChange(newValue) {

      if (newValue === '' || newValue === null || isNaN(newValue)) {
        this.termValue = null;
      } else {
        this.termValue = newValue;
      }

      this.$emit('update:keywordData', { ...this.keywordData, term_value: this.termValue !== null ? String(this.termValue) : null });
    }
  }
});
</script>
