<template>
    <div class="notification-container" :class="notification.type ? notification.type : 'secondary'" role="alert">
        <i v-if="notification.type == 'patient-arrived'" class="fal fa-calendar-check" style="color: #3ea34d;" />
        <i v-if="notification.type == 'warning'" class="fal fa-exclamation-triangle" style="color: #c52517;" />
        <div class="text-container">
            <h4 class="font-bold" v-if="notification.title">{{notification.title}}</h4>
            
            <span class="body-text">{{notification.text}}</span>
        </div>

        <button class="close-button" @click="triggerClose(notification)" aria-label="Close alert" type="button">
            <i class="fal fa-times fa-sm"/>
        </button>
    </div>
</template>

<script>
import localService from '@src/api/local';
import SettingService from '@src/api/setting';

export default {
    props: ['notification'],

    data() {
        return {
            timer: null,
            muted: Boolean,
            sound_url: '',
            publicPath: process.env.APP_URL,
        }
    },

    mounted() {
        let timeout = this.notification.hasOwnProperty('timeout') ? this.notification.timeout : true
        
        if (timeout) {
            let delay = this.notification.delay || 6000
            
            this.timer = setTimeout(function () {
                this.triggerClose(this.notification)
            }.bind(this), delay)
        }
        
        this.getSoundUrl();
        this.shouldPlaySound();
    },

    methods: {
        triggerClose: function (notification) {
            clearTimeout(this.timer)
            this.$emit('close-notification', notification)
        },
        playSound: function (sound_url = this.sound_url) {
            var sound = new Audio(sound_url);
            sound.play();
        },
        shouldPlaySound: function() {
            SettingService.getSettingsValueBySlug('mute_alert').then(res => {
                this.muted = Boolean(res);
                if (this.muted) {
                    this.playSound();
                }
            });
        },
        getSoundUrl() {
            this.sound_url = this.$http.defaults.baseURL + '/sound/624599__eqylizer__three-note-doorbell-or-notification.mp3';
        }

    },
}
</script>