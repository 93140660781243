<template lang="html">
    <div v-if="display" class="mt-2">
        <v-card :title="getTitleText" class="col-12 p-4 bg-info user-select-none mb-4">
            <div class="mx-auto mt-3 p-4 col-10">
                <div class="d-flex justify-content-between mb-4">
                    <div></div>
                    <h5 class="text-center"> {{ $t('autoletter.config.title_related.example') }}</h5> 
                    <v-btn elevation="1" prepend-icon="mdi-rotate-right" stacked variant="elevated" @click="reset()"></v-btn>
                </div>
                <editor-content class="tiptap" :editor="editor" ref="maineditor" />
                <div class="d-flex justify-content-around mt-4">
                    <v-btn prepend-icon="mdi-fingerprint" elevation="1" variant="elevated" @click="addType('ssn')" class="no-upper">
                        {{ $t('autoletter.config.title_related.ssn') }}
                    </v-btn>
                    <v-btn prepend-icon="mdi-account" elevation="1" variant="elevated" @click="addType('name')" class="no-upper">
                        {{ $t('autoletter.config.title_related.name') }}
                    </v-btn>
                    <v-btn prepend-icon="mdi-calendar-month" elevation="1" variant="elevated" @click="addType('from')" class="no-upper">
                        {{ getFromText() }}
                    </v-btn>
                    <v-btn v-if="dtype != 'tmitem'" prepend-icon="mdi-calendar-month" elevation="1" variant="elevated" @click="addType('to')" class="no-upper">
                        {{ getToText() }}
                    </v-btn>
                    <v-btn v-if="dtype != 'tmitem'" prepend-icon="mdi-office-building" elevation="1" variant="elevated" @click="addType('company')" class="no-upper">
                        {{ $t('autoletter.config.title_related.company') }}
                    </v-btn>
                </div>
            </div>
        </v-card>
    </div>
</template>
<script>

import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
    components: {
        EditorContent,
    },
    props: {
        dtype: {
            type: String,
            required: false,
            default: ''
        },
        display: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        data: {
            type: Object,
            required: false,
            default:() => {}
        }
    },
    computed: {
        getTitleText() {
            const pre = this.$t('autoletter.config.title_related.create');
            return pre + this.title;
        }
    },
    data() {
        return {
            editor: '',
            data: {},
        };
    },
    mounted() {
        this.editor = new Editor({
            content: this.data,
            extensions: [
                StarterKit
            ],
            onUpdate: ({ editor }) => {
                this.$emit('updateGeneratedTitle', editor.getJSON());
            },
        })
    },
    methods: {
        update(json) {
            this.data = json;
        },
        reset() {
            this.data = [];
            this.editor.commands.clearContent(true);
        },
        getCursorPosition() {
            let cursorPosition = 0; 
            try {
                const editor = this.$refs.maineditor.editor;
                cursorPosition = editor.state.selection.from;
            } catch {
                let editor = this.editor;
                cursorPosition = editor.state.selection.from;

            }
            return cursorPosition;
        },
        addType(type) {
            let cp = this.getCursorPosition();
            let textx = {"text":'<'+type+'>', "type":"text"};
            this.editor.commands.insertContentAt(cp , textx);
        },
        getFromText() {
            const type = this.dtype;
            let ending = '';

            if(type === 'tmitem') {
                ending = "tm_from";
            }
            if(type === 'case') {
                ending = "case_from";
            }

            if(type === 'ipitem') {
                ending = "ip_from";
            }

            return this.$t('autoletter.config.title_related.' + ending);
        },
        getToText() {
            let ending = this.dtype === 'case' ? 'case_to' : 'ip_to';
            return this.$t('autoletter.config.title_related.' + ending);
        }
    }
};
</script>
<style>
/* global, because this is bs */
.ProseMirror:focus {
    outline: none;
}
</style>
<style scoped>
.v-btn {
    height: 56px;
    color: #36454F;
}

.tiptap {
    background-color:white;
    color:#36454F; 
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 10px;
    border-radius:5px;
    margin-bottom:0;
    padding-bottom:3px;
}

.no-upper {
    text-transform: unset !important;
}

</style>
