<template>
  <v-container class="p-0">
    <term-name v-model="termName" />
    <v-combobox
      v-model="termValue"
      :items="items"
      density="compact"
      variant="outlined"
      clearable
      return-object
      hide-details
      @update:modelValue="onTermValueChange"
    />
  </v-container>
</template>

<script>
import TermName from '@src/components/journal/journalEditor/noteEditor/TermName/TermName.vue';
import KeywordValue from '@src/api/keywordvalue';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'SingleValue',
  components: {
    TermName
  },
  props: {
    keywordData: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const termName = ref('Has the patient been admitted before');
    const termValue = ref(null);
    const items = ref([]);

    const fetchDataWithParams = async (api, params) => {
      try {
        const response = await api.index(params);
        return response.data;
      } catch (error) {
        console.error('Error in fetchDataWithParams:', error);
        return [];
      }
    };

    const fetchItems = async (term_id) => {
      try {
        const response = await fetchDataWithParams(KeywordValue, { term_id });
        if (Array.isArray(response)) {
          items.value = response.map(item => item.term_value);
        } else if (response && Array.isArray(response.data)) {
          items.value = response.data.map(item => item.term_value);
        } else {
          console.error('KeywordValueData is not in the expected format', response);
          items.value = [];
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        items.value = [];
      }
    };

    watch(
      () => props.keywordData,
      (newVal) => {
        if (newVal) {
          termName.value = newVal.term_name || termName.value;
          termValue.value = newVal.term_value || termValue.value;
          if (newVal.term_id) {
            fetchItems(newVal.term_id);
          }
        }
      },
      { immediate: true }
    );

    watch(termName, (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_name: newVal });
    });

    const onTermValueChange = (newVal) => {
      emit('update:keywordData', { ...props.keywordData, term_value: newVal });
    };

    return {
      termName,
      termValue,
      items,
      onTermValueChange
    };
  }
});
</script>
