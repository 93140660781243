<template lang="html">
    <modal-simple 
        v-if="active" 
        size="large"  
        @close="emitClose()"
    >
        <template v-slot:header>
            {{ t('patient.search') }}
        </template>
        <iframe class="info-iframe" :src="src"></iframe>
        <template v-slot:footer>
            <button @click="emitClose" type="button" class="btn btn-primary"> {{ t('prescription.close') }}</button>
        </template>
    </modal-simple>
</template>
<script>
    import ModalSimple from '../modalSimple';

     export default {
        components: {
            ModalSimple
        },
        props: {
            active: { default: false },
            src: { required: true },
        },
        methods: {
            t(key) {
            return this.$t(key);
        },
            emitClose() {
                this.$emit('close');
            }
        }
    }
</script>
<style lang="css" scoped>
    iframe {
        width: 100%;
        height: 70vh;
        border: 1px solid #e5e5e5;
    }
</style>