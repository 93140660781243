
<template>
    <div class="form-group">
      <label-basic v-if="label !== undefined" :required="required">{{ label }}</label-basic>
      <input
        @change="onChange"
        @input="onInput"
        ref="input"
        :type="type"
        :value="modelValue"
        class="form-control"
        :class="{'is-invalid': error}"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :tabindex="String(tabindex)"
        style="min-height: 2.5rem;"
      />
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import LabelBasic from '../labelBasic';
  
  export default defineComponent({
    components: {
      LabelBasic,
    },
    props: {
      modelValue: {
        required: false,
        default: '',
      },
      required: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: undefined,
      },
      type: {
        type: String,
        default: 'text',
      },
      tabindex: {
        type: String,
        default: '',
      },
    },
    emits: ['update:modelValue', 'change'],
    methods: {
      onInput(event) {
        this.$emit('update:modelValue', event.target.value);
      },
      onChange(event) {
        this.$emit('change', event.target.value);
      },
    },
  });
  </script>
  