<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card>
          <v-toolbar>
            <v-app-bar-nav-icon icon="mdi-view-agenda"></v-app-bar-nav-icon>
            <v-toolbar-title class="user-select-none">{{ $t('doctor_dashboard.agenda') }}</v-toolbar-title>
          </v-toolbar>
          <v-list lines="two">
            <v-list-subheader>{{ $t('doctor_dashboard.today') }}</v-list-subheader>
            <v-list-item v-if="done && agendas.length == 0" class="d-flex justify-center">
              <h3 class="text-center">
                <v-icon color="grey" size="large">mdi mdi-calendar-alert</v-icon>
              </h3> 
              <p class="text-grey text-center">{{ $t('doctor_dashboard.no_appointments') }}</p>
            </v-list-item>
            <div v-for="(entry,i) in agendas" :key="entry.id">
              <AgendaRow :data="entry" :hasUnpaid="hasUnpaid" :hasPaid="hasPaid" :value="i" />
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import TmItemApi from '@src/api/tmitem';
import localService from '@src/api/local';
import AgendaRow from './agendaRow.vue';

import { useStore } from 'vuex';
import { useSnackbarStore } from '@stores/snackbarStore';

export default {
  components: { AgendaRow },
  created() {
    this.getTodayAgendas();
  },
  data() {
    return {
      agendas: [],
      hasPaid: [],
      hasUnpaid: [],
      store: useStore(),
      snackbarStore: useSnackbarStore(),
      done: false
    }
  },
  methods: {
    getTodayAgendas() {
      const currentUserID = this.store.getters.currentUserID;
      const today = new Date().toISOString().slice(0, 10);
      const start = new Date(today).setHours(0,0,0,0);
      const end = new Date(today).setHours(23,59,59,999);

      const data = {
        'user_id': currentUserID,
        'start_date': new Date(start.valueOf()),
        'end_date': new Date(end.valueOf()),
        'unit_id': '', 'resource_id': '', 'code_id': ''
      };

      TmItemApi.index(data)
        .then(res => { 
          let agendas = res.data;
          agendas.map(val => (val.hasInvoice = false, val.printed = false));
          
          localService.getUserInvoicesLight(currentUserID, today)
            .then(res => {
              let data = res.data;
              for(const item of data) {
                const codenumber = item.patient.codenumber, printed = item.printed == '1' ? true : false;
                printed ? this.hasPaid.push(codenumber) : this.hasUnpaid.push(codenumber);
                agendas.map(a => a.PatientCodeNumber === codenumber ? ( a['hasInvoice'] = true, a['printed'] = printed ) : '');
              }
              this.agendas = agendas;
              this.done = true;
            })
          .catch(err => {
              console.log(err);
              this.snackbarStore.activate('Agenda fetch failed', 'error', 2000);
          });
        })
        .catch(err => {
          console.log(err);
          this.snackbarStore.activate('Agenda fetch failed', 'error', 2000);
      });
    }
  }
};
</script>
