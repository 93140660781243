<template>
    <div v-if="!preview" class="d-flex my-4 justify-content-center">
        <div v-if="debug && !fromHeader">
            <v-btn prepend-icon="mdi-test-tube" @click="storeAndSend(true)" :disabled="storeDisabled || dobMissing" variant="outlined" class="mx-auto mt-4 no-upper" color="primary">
                PDF Test
            </v-btn>
        </div>
        <v-btn v-if="fromHeader" icon @click="storeAndSend(false)" :disabled="storeDisabled || dobMissing">
            <v-icon>mdi-floppy</v-icon>
        </v-btn>
        <v-btn v-if="fromHeader" icon @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </div>
    <!-- THE XML DTO -->
    <div ref="xml" style="background-color:black; padding:20px" :class="!debug ? 'hidden' : '' ">
        <h6 class="tag">{{ `<${top}>` }}</h6>
        <!-- BASE STARTS -->
        <div v-for="(bl, index) in base1" :key="index">
            <small class="tag">{{ `<${bl}>` }}</small>
            <small class="value">{{ baseData(bl) }}</small>
            <small class="tag">{{ `</${bl}>` }}</small>
        </div>
        <!-- BASE ENDS -->
        <!-- NESTED STARTS -->
        <div v-for="(category, ci) in categories" :key="ci"> 
            <small class="tag">{{ `<${category}>` }}</small>
            <div class="ml-2" v-for="(entry, ei) in nested[category]" :key="ei">
                <small class="ml-4 tag">{{ `<${entry}>` }}</small>
                <small class="value" >{{ nestedData(entry, category) }}</small>
                <small class="tag">{{ `</${entry}>` }}</small>
            </div>
            <small class="tag">{{ `</${category}>` }}</small>
            <div v-if="ci === 2">
                <div v-for="(bl, index) in base2" :key="index">
                    <small class="tag">{{ `<${bl}>` }}</small>
                    <small class="value">{{ baseData(bl) }}</small>
                    <small class="tag">{{ `</${bl}>` }}</small>
                </div>
            </div>
        </div>
        <!-- NESTED ENDS -->
        <div v-for="(bl, index) in base3" :key="index">
            <small class="tag">{{ `<${bl}>` }}</small>
            <small class="value">{{ baseData(bl) }}</small>
            <SheetDataCreator v-if="bl === 'SheetData'" @updateForm="updateForm" :form="form" :data="data" :debug="debug" />
            <small class="tag">{{ `</${bl}>` }}</small>
        </div>
        <h6 class="tag">{{ `</${top.split(' ')[0]}>` }}</h6>
    </div>
</template>
<script>

import { v4 as uuidv4 } from 'uuid';
import SheetDataCreator from './helpers/sheetDataCreator.vue'

export default {
    components: { SheetDataCreator },
    props: {
        preview: {
            type: Boolean,
            required: false,
            default: false
        },
        storeDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        kind: {
            type: String,
            required: false,
            default: ''
        },
        address: {
            type: Object,
            required: false,
            default: () => {}
        },
        form: {
            type: Object,
            required: false,
            default: () => {}
        },
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        fromHeader: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        this.initBase();
        this.initPatient();
        this.initSender();
        this.initSignature();
        this.initReceiver();
    },
    data() {
        return {
            op: '<',
            m: '</',
            cl: '>',

            top: 'ElectronicSheetDTO xmlns="http://www.emr.is/schema/ElectronicSheet/v1.0"',
            categories: ['PatientInfo', 'SenderInfo', 'SignatureInfo', 'ReceiverInfo'],
            base1: ['IsDynamicForm', 'Id', 'DateTime', 'PatientSSN'],
            base2: ['ReceivingNode', 'ReceivingGroupId'],
            base3: ['KindOfSheet', 'Status', 'SheetData', 'Message'],
            nested: {
                PatientInfo: ['Name', 'HomePhone', 'WorkPhone', 'Email', 'DateOfBirth', 'Citizenship', 'CitizenshipCountryCode', 'Address2', 'ZipCode', 'Sex', 'DiscountCard', 'DiscountCardDate'],
                SenderInfo: ['PersonName', 'PersonSSN', 'Department', 'Organization'],
                SignatureInfo: ['PersonName', 'PersonExternalId', 'PersonRole', 'Organization', 'Department', 'SignitureDate'],
                ReceiverInfo: [ 'PersonName', 'PersonExternalNumber']
            },
            baseValues: {}, patientValues: {}, senderValues: {}, signatureValues: {}, receiverValues: {},
            // this is the form part only 
            formPart: '',
            // date of birth is required
            dobMissing: false,
            uuid: ''
        };
    },
    watch: {
        address: {
            handler: function() {
                const address = this.getAddress();
                this.baseValues['ReceivingNode'] = address.node;
                this.baseValues['ReceivingGroupId'] = address.groupId;
                this.initReceiver();
            },
            deep: true
        },
        kind() {
            this.baseValues['KindOfSheet'] = this.kind;
        }
    },
    methods: {
        getAddress() {
            let node = '', groupId = '';
            if('eid' in this.address && this.address.eid.indexOf(';') > 0) {
                node = this.address.eid.split(';')[0];
                groupId = this.address.eid.split(';')[1];
            }
            return { node: node, groupId: groupId };
        },
        updateForm(formPart) {
            this.formPart = formPart;
        },
        storeAndSend(test) {
            this.initPatient();
            if(!this.$refs.xml) {
                console.log('error: xml ref not found');
                return;
            }

            // for validation (if xsd exists)
            let formPart = this.formPart;
            if(formPart.length > 0) {
                formPart = formPart.join('');
                while(formPart.indexOf('  ') > 0) {
                    formPart = formPart.replace('  ',' ');
                }
            }

            let dto = this.$refs.xml.innerText;
            const si = dto.indexOf('<SheetData'), ei = dto.indexOf('<Message');
            const pre = dto.substring(0, si), post = dto.substring(ei);

            // html encode
            let encodedString = document.createElement('div');
            encodedString.innerText = formPart;
            dto = pre + '<SheetData>' + encodedString.innerHTML + '</SheetData>' + post;

            this.$emit('store', dto, formPart, this.uuid, test);
        },
        close() {
            this.$emit('close');
        },
        initBase() {
            const address = this.getAddress();
            // Einkvæmt auðkenni (GUID) skeytis, sem sendandi stofnar
            this.baseValues['IsDynamicForm'] = '1';
            // Einkvæmt auðkenni (GUID) skeytis, sem sendandi stofnar
            this.uuid = uuidv4();
            this.baseValues['Id'] = this.uuid;
            // Tíminn þegar skeytið er sent
            this.baseValues['DateTime'] = new Date().toISOString().replace('Z','');
            // Kennitala sjúklings
            this.baseValues['PatientSSN'] =  this.$store.getters['currentPatientCodenumber'];
            // Nafn Heklugáttar sem skeytið á að berast til
            this.baseValues['ReceivingNode'] = address.node;
            // Númer viðtökudeildar (úr stofnanaskrá)
            this.baseValues['ReceivingGroupId'] = address.groupId;
            // Númer (tegund) eyðublaðsins sem er verið að senda
            this.baseValues['KindOfSheet'] = this.kind;
            // Staða skeytisins. Þegar rafrænt skeyti er sent þá á staðan alltaf að vera "ReceivingQueue"
            this.baseValues['Status'] = 'ReceivingQueue';
            // Gögnin sjálf
            this.baseValues['SheetData'] = '';
            // Frjáls skilaboð sem notandi (sendandi) vill láta fylgja með
            this.baseValues['Message'] = ''
        },
        initPatient() {
            // Nafn sjúklings
            this.patientValues['Name'] = this.$store.getters['currentPatientName'];
            // Heimasími
            this.patientValues['HomePhone'] = this.$store.getters['currentPatientPhoneNumber'];
            // Vinnusími
            this.patientValues['WorkPhone'] = this.$store.getters['currentPatientWorkPhone'];
            // Netfang
            this.patientValues['Email'] = this.$store.getters['currentUserEmail'];
            // Fæðingardagur. Þetta svæði er fyrst og fremst hugsað ef um er að ræða
            // erlendan einstakling sem er ekki með íslenska kennitölu
            let bd = this.$store.getters['currentPatientBirthDate'];
            if(!(bd === undefined) && bd.indexOf(' ') === 10) {
                bd = bd.replace(' ', 'T');
            } else {
                console.log('ERROR: date of birth is missing');
                this.dobMissing = true;
                // show error message
                this.$emit('dobMissing');
            }
            this.patientValues['DateOfBirth'] = bd;
            this.patientValues['Citizenship'] = 0;
            // Ríkisfang. 2ja stafa alþjóðlegur ISO kóði
            this.patientValues['CitizenshipCountryCode'] = '';
            // Heimilisfang, lína 1
            this.patientValues['Address1'] = this.$store.getters['currentPatientAddress'];
            // Heimilisfang, lína 2
            this.patientValues['Address2'] = '';
            // Póstnúmer
            this.patientValues['ZipCode'] = this.$store.getters['currentPatientAddressZip'];
            // Kyn, sbr. ISO-5218.
            // 0=Óþekkt, 1=Karl, 2=Kona, 9=Óskilgreint
            let sex = this.$store.getters['currentPatientSex'];
            sex === 'M' ? sex = 1 : '';
            sex === 'F' ? sex = 2 : '';
            sex === undefined  ? sex = 9 : '';

            this.patientValues['Sex'] = sex;
            // Upplýsingar um afsláttarkort
            this.patientValues['DiscountCard'] = '';
            // Dagsetning afsláttarkorts
            this.patientValues['DiscountCardDate'] = '';
        },
        initSender() {
            // Nafn sendanda
            this.senderValues['PersonName'] = this.$store.getters['currentUserName'];
            // Kennitala sendanda
            this.senderValues['PersonSSN'] = this.$store.getters['currentUserCodenumber'];
            // Heiti stofnunar (sem skeytið er sent frá)
            this.senderValues['Department'] = this.$store.getters['currentUnitDepartment'];
            // Deild (innan stofnunar sem sendir skeytið)
            this.senderValues['Organization'] = this.$store.getters['currentUnitInstitute'];
        },
        initSignature() {
            this.signatureValues['PersonName'] = '';
            this.signatureValues['PersonExternalId'] = '';
            this.signatureValues['PersonRole'] = '';
            this.signatureValues['Organization'] = '';
            this.signatureValues['Department'] = '';
            this.signatureValues['SignitureDate'] = new Date().toISOString().replace('Z','');
        },
        initReceiver() {
            // Auðkennið sem sótt var út miðlægu stofnanaskránni
            this.receiverValues['Id'] = 'eid' in this.address ? this.address.eid : '';
            // Heiti stofnunar
            this.receiverValues['Organization'] = 'name' in this.address ? this.address.name : '';
            // Heiti deildar
            this.receiverValues['Department'] = '';
            // Heiti viðtakanda, ef skeytið á að berast ákveðnum aðila
            this.receiverValues['PersonName'] = '';
            // Einkvæmt auðkennisnúmer (t.d. læknanúmer)
            this.receiverValues['PersonExternalNumber'] = '';
        },
        baseData(tag) {
            if(tag in this.baseValues) {
                return this.baseValues[tag];
            }
            return '';
        },
        nestedData(tag, category) {
            if(category === 'PatientInfo') {
                return this.patientValues[tag];
            }
            if(category === 'SignatureInfo') {
                return this.signatureValues[tag];
            }
            if(category === 'SenderInfo') {
                return this.senderValues[tag];
            }
            if(category === 'ReceiverInfo') {
                return this.receiverValues[tag];
            }
        }
    }
};
</script>
<style scoped>
.tag {
    color: white;
    font-weight: bold;
}
.value {
    color: orange;
    font-weight: bolder;
}
.hidden {
    position:absolute;
    transform:scale(0);
}
</style>
