<!-- <template lang="html">
  <div class="container">
      <prescription-form 
          :errors="errors" 
          @send="onSend" 
          @lookup="onLookUp"
      />
      <modal-loading :active="loading" />
      <modal-iframe :active="iframe" :src="lgn" @close="iframe = false" />
      <modal-error :active="hasError" :error="errorMsg" @close="clearError" />
  </div>
</template>
<script>
import localService from '../../api/local';
import { ADD_DOCTOR_PRESCRIPTION } from '../../store/mutationTypes';
import * as HttpStatus from 'http-status-codes';
import PrescriptionForm from './prescriptionForm';
import ModalLoading from '../modalLoading';
import ModalIframe from '../modalIframe';
import ModalError from '../modalError';

export default {
  components: {
      PrescriptionForm,
      ModalLoading,
      ModalIframe,
      ModalError,
  },
  data() {
      return {
          hasError: false,
          errors: {},
          loading: false,
          iframe: false,
          lgn: '',
          errorMsg: '',
          errorCode: undefined
      };
  },
  methods: {
      onSend(params) {
          this.errors = {};
          this.loading = true;
          localService.postPrescription(params).then(res => {
              res.data.forEach(prescription => {
                  this.$store.commit(ADD_DOCTOR_PRESCRIPTION, prescription);
              });
              this.$router.push({ name: 'presc-index' });
          }).catch(err => {
              this.hasError = true;
              this.handleErrorResponse(err.response);
          }).then(() => this.loading = false);
      },
      onLookUp(src) {
          this.lgn = src;
          this.iframe = true;
      },
      handleErrorResponse(response) {
          switch (response.status) {
          case HttpStatus.UNPROCESSABLE_ENTITY:
              this.errors = response.data.errors;
              if (Object.keys(this.errors).find(err => err.includes('usercode'))) {
                  this.errorMsg = 'Uppsetning notanda er ekki fullnægjandi';
              } else {
                  this.errorMsg = 'Vinsamlegast leiðréttið innsláttarvillur';
              }
              break;
          case HttpStatus.SERVICE_UNAVAILABLE:
              this.errorMsg = 'Næst ekki samband við Heklu þjónustu';
              break;
          default: 
              this.errorMsg = 'Óþekkt villa í netþjón';
              break;
          }
      },
      clearError() {
          this.hasError = false;
          this.errorMsg = '';
      }
  }
};
</script> -->







<template>
  <div class="container">
    <PrescriptionForm 
      :errors="errors" 
      @send="onSend" 
      @lookup="onLookUp"
      @close="close"
    />
    <ModalLoading :active="loading" />
    <ModalIframe :active="iframe" :src="lgn" @close="iframe = false" />
    <ModalError :active="hasError" :error="errorMsg" @close="clearError" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import localService from '../../api/local';
import { ADD_DOCTOR_PRESCRIPTION } from '../../store/mutationTypes';
import * as HttpStatus from 'http-status-codes';

// eGatt version
// import PrescriptionForm from '@components/prescriptionCreate/prescriptionForm/prescriptionForm.vue';
// Signal version
import PrescriptionForm from '@components/prescriptionCreate/prescriptionForm/prescriptionFormSignal.vue';

import ModalLoading from '@components/modalLoading/modalLoading.vue';
import ModalIframe from '@components/modalIframe/modalIframe.vue';
import ModalError from '@components/modalError/modalError.vue';

export default defineComponent({
  components: {
    PrescriptionForm,
    ModalLoading,
    ModalIframe,
    ModalError,
  },
  props: {
    dataRowId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hasError: false,
      errors: {},
      loading: false,
      iframe: false,
      lgn: '',
      errorMsg: '',
      errorCode: undefined
    };
  },
  methods: {
    onSend(params) {
      this.errors = {};
      this.loading = true;
      localService.postPrescription(params).then(res => {
        res.data.forEach(prescription => {
          this.$store.commit(ADD_DOCTOR_PRESCRIPTION, prescription);
        });
        this.$router.push({ name: 'presc-index' });
      }).catch(err => {
        this.hasError = true;
        this.handleErrorResponse(err.response);
      }).then(() => this.loading = false);
    },
    onLookUp(src) {
      this.lgn = src;
      this.iframe = true;
    },
    handleErrorResponse(response) {
      switch (response.status) {
        case HttpStatus.UNPROCESSABLE_ENTITY:
          this.errors = response.data.errors;
          if (Object.keys(this.errors).find(err => err.includes('usercode'))) {
            // this.errorMsg = 'Uppsetning notanda er ekki fullnægjandi';
            this.errorMsg = this.$t('drug_database.error_setup');

          } else {
            this.errorMsg = this.$t('drug_database.correct_typos');
          }
          break;
        case HttpStatus.SERVICE_UNAVAILABLE:
          this.errorMsg =  this.$t('drug_database.unable_to_connect');
          break;
        default: 
        this.errorMsg = this.$t('drug_database.unknown_error');
          break;
      }
    },
    clearError() {
      this.hasError = false;
      this.errorMsg = '';
    },
    close() {
      this.$emit('close');
    }
  }
});
</script>
