<template>
  <v-row align="center">
    <v-col align="start">
      <div v-if="isEditing" class="d-flex justify-content-between align-items-center mt-4">
        <v-text-field v-model="internalTermName" ref="itn" density="compact" variant="outlined" class="w-100" />
        <v-btn icon @click="saveTermName" class="save-btn ml-4" density="compact">
          <v-icon size="x-small">mdi-check</v-icon>
        </v-btn>
      </div>
      <div v-else @mouseover="showEditButton = true" @mouseleave="showEditButton = false" @mouseexit="showEditButton = false" class="term-name">
        <div class="btn-height user-select-none"><b>{{ modelValue }}</b></div>
        <v-btn icon v-if="showEditButton" @click="editButtonPressed()" density="compact" class="mb-1">
          <v-icon size="x-small">mdi-pencil</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TermName",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      showEditButton: false,
      internalTermName: this.modelValue,
    };
  },
  watch: {
    modelValue(newTermName) {
      this.internalTermName = newTermName;
    },
  },
  methods: {
    editButtonPressed() {
      this.isEditing = true;
      // set focus on newly opened edit
      this.$nextTick(() => { this.$refs.itn ? this.$refs.itn.focus() : ''; });
    },
    saveTermName() {
      this.isEditing = false;
      this.showEditButton = false;
      this.$emit("update:modelValue", this.internalTermName);
    },
  },
};
</script>

<style scoped>
.term-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.save-btn {
  margin-left: auto;
  margin-bottom: 20px;
}

.btn-height {
  height: 32px;
  padding-top: 10px;
}
</style>
