<template>
  <div class="col-10 mx-auto mt-0 pt-0 mb-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <v-text-field v-model="searchQuery" :label="$t('journal.editor-menu.search')" clearable
        @click:clear="clearSearch" prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
    </div>
    <div class="d-flex flex-column">
      <div class="pointer-cursor px-0" v-for="editor in filteredEditors" :key="editor.type" @click="selectEditor(editor.type)">
        <div class="d-flex flex-row justify-content-between py-1 choice">
          <!-- ELECTRONIC SHEETS -->
          <div v-if="['-1','5'].includes(editor.type)" class="d-flex justify-content-start">
            <v-icon class="icon" :color="editor.color">{{ editor.icon }}</v-icon>
            <span class="ml-2">{{ editor.name }}</span>
          </div>
          <!-- OTHER TYPES -->
          <div v-else class="d-flex justify-content-start">
            <v-icon class="icon" v-if="editor.icon.startsWith('mdi')" :color="editor.color">{{ editor.icon }}</v-icon>
            <i v-else class="icon-strange" :class="editor.icon" :style="{ color: getColor(editor.color) }"></i>
            <span class="ml-2">{{ editor.name }}</span>
          </div>
          <!-- COLLAPSE ICONS -->
          <div v-if="editor.type === '-1'">
            <v-icon v-if="expandedSheets" class="ml-auto">mdi-unfold-more-horizontal</v-icon>
            <v-icon v-else class="ml-auto">mdi-unfold-less-horizontal</v-icon>
          </div>
          <div v-if="editor.type === '5'">
            <v-icon v-if="expandedNotes" class="ml-auto">mdi-unfold-more-horizontal</v-icon>
            <v-icon v-else class="ml-auto">mdi-unfold-less-horizontal</v-icon>
          </div>
        </div>
        <!-- LIST FOR NOTES -->
        <v-expand-transition appear leave>
          <div v-if="editor.type === '5' && expandedNotes" class="d-flex flex-column">
            <ul 
              v-for="item in filteredNotes" 
              :key="item.template_id"
              style="list-style:none" 
              class="pointer-cursor px-0 notes-list mb-0" 
              @click="item.isFreeText ? openFreeTextNoteEditor() : navigateToNoteEditor(item.template_id)"
            >
              <li class="d-flex align-items-center">
                <v-icon class="icon" color="primary">mdi-star-four-points-small</v-icon>
                <span class="ml-0">{{ item.template_name }}</span>
              </li>
            </ul>
          </div>
        </v-expand-transition>
        <v-expand-transition appear leave>
          <!-- LIST FOR ES -->
          <div v-if="editor.type === '-1' && expandedSheets" class="d-flex flex-column">
            <ul 
              v-for="item in filteredSheets"
              :key="item.id"
              style="list-style:none" 
              class="pointer-cursor px-0 notes-list"
              @click="selectSheet(item)"
            >
              <li class="d-flex align-items-center">
                <v-icon class="icon" color="primary">mdi-star-four-points-small</v-icon>
                <span class="ml-0">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </v-expand-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useJournalStore } from '@src/stores/journalStore';
import AttentionEditor from '@src/components/journal/journalEditor/attentionEditor/attentionEditor.vue';
import DiagnoseEditor from '@src/components/journal/journalEditor/diagnosisEditor/diagnosisEditor.vue';
import NoteEditor from '@src/components/journal/journalEditor/noteEditor/noteEditor.vue';
import labResultEditor from '@src/components/journal/journalEditor/labResultEditor/labResultEditor.vue';
import cbmtemplate from '@src/api/cbmtemplate';

export default {
  components: {
    AttentionEditor, DiagnoseEditor, NoteEditor, labResultEditor
  },
  props: {
    sheets: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  watch: {
    searchQuery() {
      if(this.searchQuery.length > 0) {
        this.expandedNotes = true;
        this.expandedSheets = true;
      }
    }
  },
  data() {
    return {
      // { type: '4', name: this.$t('journal.editor-menu.lab'), icon: 'mdi-flask', color: 'primary' },
      searchQuery: '',
      editors: [
        { type: '5', name: this.$t('journal.editor-menu.note'), icon: 'mdi-file', color: 'primary' },
        { type: '16', name: this.$t('journal.editor-menu.prescription'), icon: 'mdi-prescription', color: 'primary' },
        { type: '2', name: this.$t('journal.editor-menu.diagnose'), icon: 'fa fa-solid fa-person-dots-from-line', color: 'primary' },
        { type: '-1', name: this.$t('journal.electronic_sheets.name'), icon: 'mdi-email-newsletter', color: 'primary' },
        { type: '9', name: this.$t('journal.editor-menu.attention'), icon: 'mdi-alert-circle', color: 'primary' }
      ],
      cbmData: [
        { template_id: null, template_name: this.$t('journal.editor-menu.free_text'), isFreeText: true },
      ],
      expandedNotes: false,
      expandedSheets: false
    };
  },
  computed: {
    ...mapState(useJournalStore, ['editor']),
    filteredEditors() {
      const query = this.searchQuery.toLowerCase();
      let result = [];
      let showSheets = this.sheets.some(item => item.name.toLowerCase().includes(query));
      let showNotes = this.cbmData.some(item => item.template_name.toLowerCase().includes(query));

      for(let editor of this.editors) {
        if(editor.type === '-1') {
          showSheets ? result.push(editor) : '';
          continue;
        }
        if(editor.type === '5') {
          showNotes ? result.push(editor) : '';
          continue;
        }
        editor.name.toLowerCase().includes(query) ? result.push(editor) : '';
      }
      return result;
    },
    filteredNotes() {
      const query = this.searchQuery.toLowerCase();
      return this.cbmData.filter(item => item.template_name.toLowerCase().includes(query));
    },
    filteredSheets() {
      const query = this.searchQuery.toLowerCase();
      return this.sheets.filter(item => item.name.toLowerCase().includes(query));
    }
  },
  mounted() {
    this.initializeComponent();
  },
  methods: {
    async initializeComponent() {
      try {
        const response = await this.fetchDataWithParams(cbmtemplate);
        this.cbmData = [...this.cbmData, ...response.data]; // Append the fetched data to cbmData
      } catch (error) {
        console.error('Error initializing component:', error);
      }
    },
    async fetchDataWithParams(api, params = {}) {
      try {
        return await api.index(params);
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    },
    selectSheet(sheet) {
      this.$emit('sheetSelected', sheet.id);
    },
    selectEditor(editorType) {
      // collapseble
      if(['-1','5'].includes(editorType)) {
        if(editorType === '-1') {
          this.expandedSheets = !this.expandedSheets;
        } else {
          this.expandedNotes = !this.expandedNotes;
        }
        return;
      }

      this.$emit('select-editor', editorType);
      const journalStore = useJournalStore();

      journalStore.setEditor({
        type: editorType,
      });
    },
    navigateToNoteEditor(templateId) {
      this.$emit('select-editor', '5');
      localStorage.setItem('templateId', templateId);

      const journalStore = useJournalStore();
      journalStore.setEditor({
        type: '5',
        templateId: templateId,
      });
    },
    openFreeTextNoteEditor() {
      this.$emit('select-editor', '5');
      const journalStore = useJournalStore();
      journalStore.setEditor({
        type: '5', // Note Editor type
        templateId: null, // No template for free text
        isFreeText: true, // Flag for free text mode
        cbm_keyword : "ahbkbkjb",
        title :"Note"
      });
    },
    clearSearch() { 
      this.searchQuery = '';
    },
    getColor(color) {
      const colorMap = {
        primary: '#1976d2',
        secondary: '#009688',
      };
      return colorMap[color] || color;
    },
  },
};
</script>

<style scoped>
.favorites {
margin-top: 50px;
}

.pointer-cursor {
cursor: pointer;
}

.icon {
width: 25px;
}

/* because icon is different */
.icon-strange {
width: 18px;
margin: 5px 5px 0 3px;
}

.notes {
margin-top:1.5rem;
margin-bottom: 10px;
cursor:default;
}
.notes-list {
margin-left:10px;
margin-bottom: 0;
}

.choice:hover, li:hover {
color: rgba(var(--v-theme-primary),0.9);
/*width: fit-content;*/
}
</style>
